import React from 'react';
import classnames from 'classnames'

const InputControl = ({ fieldName, value, type, onChange, error, placeholder, disabled, required }) => {
    return (
        <React.Fragment>
            <div className="control margin-bottom">
                <input id={fieldName} onChange={onChange} value={value} type={type} name={fieldName} placeholder={placeholder}
                    className={classnames({ 'input': (type !== 'checkbox' && type !== 'submit'), 'is-danger': error, 'button is-primary': type === 'submit', 'is-checkradio': type === 'checkbox' })} 
                    disabled={disabled} required={required} width="100%"/>
                    {type === 'checkbox' && <label htmlFor={fieldName}></label>}
            </div>
            {error && <p className="help is-danger">{error}</p>}
        </React.Fragment>
    );
}

export default InputControl;
