import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import "./message.scss";

export function toastMessage(message, code, translate = false, autoClose = 10000) {
    var color = getColor(code);
    toast(
        <article className={classnames("message", color)}>
            <div className="message-body">
                <p><span>{translateMessage(message, translate)}</span></p>
            </div>
        </article>, {
        className: classnames("message", color),
        autoClose: autoClose,
        progressClassName: `${color}-bar`
    })
}
function translateMessage(message, translate) {
    if (message.startsWith("Error_Message_")) {
        message = message.replace("Error_Message_", "Error.Message.");
        return <Trans i18nKey={`IRating.${message}`} options={{ renderInnerHtml: true }}/>;
    }
    else if (translate){
        return <Trans i18nKey={`${message}`} options={{ renderInnerHtml: true }}/>;
    }
    return message;
}

function getColor(code) {
    if (code >= 500) {
        return "is-danger";
    }
    else if (code >= 400 && code < 500) {
        return "is-warning";
    }
    else if (code >= 200 && code < 300) {
        return "is-success";
    }
    else {
        return "is-info";
    }
}

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getErrorColor(code) {
        if (code >= 500) {
            return "is-danger";
        }
        else if (code >= 400 && code < 500) {
            return "is-warning";
        }
        else if (code >= 200 && code < 300) {
            return "is-success";
        }
        else {
            return "is-info";
        }
    }

    render() {
        var color = this.getErrorColor(this.props.error.code);
        toast(<article className={classnames("message", color)}>
            <div className="message-body">
                <span>{this.translateMessage(this.props.error.message)}</span>
            </div>
        </article>, {
            className: classnames("message", color),
            autoClose: this.props.autoClose,
            progressClassName: `${color}-bar`
        })
        return null;
    }

    translateMessage(message) {
        if (message.startsWith("Error_Message_")) {
            message = message.replace("Error_Message_", "Error.Message.");
            return this.props.t(`IRating.${message}`);
        }
        return message;
    }
}

Message.defaultProps = {
    autoClose: 8000
}

Message.propTypes = {
    error: PropTypes.object.isRequired
}

export default withTranslation("translation")(Message);