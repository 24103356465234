import React from "react";
import classnames from "classnames";
import { connect } from 'react-redux';
import { withTranslation, Trans  } from 'react-i18next';

class OldStyleSection extends React.Component {

    render() {
        return (
            <div className={classnames('old-style-report-section', { 'is-expanded': true, 'old-style-no-data': !this.props.hasData })} ref={el => this.holder = el}>
                <div className="old-style-header-body">
                    <div style={{fontWeight: this.props.isBold && this.props.hasData ? "bold":"normal"}}>{this.props.name}</div>
                    {!this.props.hasData && !this.props.isReliability && <span style={{marginLeft:'auto'}}><Trans i18nKey='Report.Common.NoData' /></span>}
                    {this.props.isReliability && <span style={{marginLeft:'auto'}}><Trans i18nKey= {this.props.hasData ? 'Common.Yes' : 'Common.No'} /></span>}
                </div>
                { this.props.hasData && <>
                    {this.props.hasSpace && <div style={{ paddingTop: ".25em"}}></div>}
                    {this.props.children}
                    </>}
            </div>
        )
    }
    
}
export default OldStyleSection = withTranslation("translation")(connect()(OldStyleSection));