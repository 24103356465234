import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({ 
    lng: localStorage.getItem('locales_lang') || "sk-SK",
    resources: {
      "sk-SK": {
        "translation": {}
      },
      "cs-CZ": {
        "translation": {}
      },
      "de-DE": {
        "translation": {}
      },
      "en-US": {
        "translation": {}
      },
    },
    fallbackLng: 'sk-SK',
    parseMissingKeyHandler: (key) => `-${key}-`
});

  export default i18n;