import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../../store/appContextStore'
import { isObjectPathDefined, hasPermission, isOnPath } from '../../store/apiWrapper'
import { writeDebug } from './logger'

const PrivateRoute = ({ component: Component, user, componentProps, ...rest }) => (
    <Route path={rest.path} render={(props) => {
        if(user === undefined || user.authentication === undefined || !user.authentication.isAuthorized){
            if(!isOnPath('/login')){
                writeDebug("Redirecting to login page, return url:"+encodeURIComponent(props.match.url));
                return (<Redirect to={`/login?returnUrl=${encodeURIComponent(props.match.url)}`} />)
            } else {
                return (<Component {...props} {...componentProps} />)
            }
        } else {
            if(!isObjectPathDefined(rest, 'permission')) {
                return (<Component {...props} {...componentProps} />)
            }
            if (hasPermission(user, rest.permission)) {
                return (<Component {...props} {...componentProps} />)
            }
            writeDebug("Redirecting to not authorized page, return url:"+encodeURIComponent(props.match.url));
            return (<Redirect to={`/notAuthorized?returnUrl=${encodeURIComponent(props.match.url)}`} />)
        }
    }} />
)

export default connect(
    state => ({ user: state.appContext }),
    dispatch => bindActionCreators(appContextActions, dispatch)
)(PrivateRoute)