import React from 'react'
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/lib/connect/connect';
import { isNullOrUndefined } from '../../store/storeFunctions';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { appContextActions } from '../../store/appContextStore'
import Loading from '../common/loading/loading';
import Message from '../common/message/message';

class OfferResultComponent extends React.Component {
    state = { name: "" }
    componentDidMount() {
        this.props.getOffers(this.state.name);
    }


    render() {
        return (
            <div className="columns work-container is-desktop">
                <div className="column left-navbar-container">
                </div>
                <div className="column right-body-container is-three-quarters">
                    {this.renderError(this.props.offerSearch)}
                    {this.renderResult(this.props.offerSearch)}
                    {this.renderLoading()}
                </div>
                <div className="column">
                </div>
            </div>
        )
    }

    renderLoading = () => {
        if (!this.props.offerSearch.isSearching)
            return null;
        return (<Loading />)
    }

    renderError = (sr) => {
        if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.error)) {
            return (<Message error={sr.error.data.error} />);
        }
        else if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.offerResult) && !isNullOrUndefined(sr.offerResult.error)) {
            return (<Message error={sr.offerResult.error} />);
        }
    }

    renderResult = (res) => {
        if (!isNullOrUndefined(res) && (!isNullOrUndefined(res.error) || (!isNullOrUndefined(res.offerResult) && !isNullOrUndefined(res.offerResult.error)))) {
            return null;
        }
        if (isNullOrUndefined(res) || isNullOrUndefined(res.offerResult) || isNullOrUndefined(res.offerResult.data))
            return null;
        return (
            <div className="box table-container">
                <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                    <tbody>
                        <tr>
                            <th>{this.props.t('IRating.Offer.Name')}</th>
                            <th>{this.props.t('IRating.Offer.CreationDate')}</th>
                            <th>{this.props.t('IRating.Offer.ValidTo')}</th>
                            <th>{this.props.t('IRating.Offer.Valid')}</th>
                            <th>{this.props.t('IRating.Offer.Rating')}</th>
                            <th>{this.props.t('IRating.Offer.Partner')}</th>
                            <th></th>
                        </tr>
                        {res.offerResult.data.map((r, i) =>

                            <tr key={i}>
                                <td>
                                    {r.name}
                                </td>
                                <td>
                                    {moment(r.creationDate).format('L')}
                                </td>
                                <td>
                                    {moment(r.validTo).format('L')}
                                </td>
                                <td>
                                    {r.valid && <FontAwesomeIcon icon="check" />}
                                </td>
                                <td>
                                    {(r.ratingFrom === r.ratingTo ? `${r.ratingFrom}` : `${r.ratingFrom}/${r.ratingTo}`)}
                                </td>
                                <td>
                                    {r.partner}
                                </td>
                                <td>
                                    <Link className="button" to={`/offer/${r.name}`}>{this.props.t('IRating.History.Show')}</Link>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        );
    }
}

export default withTranslation("translation")(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(OfferResultComponent));