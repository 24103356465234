import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment'
import 'moment/locale/sk';
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router';
import { withTranslation } from 'react-i18next';
import { renderToStaticMarkup } from "react-dom/server";

import { appContextActions } from './store/appContextStore'
import { getLanguage } from './store/apiWrapper';

import PrivateRoute from './components/common/privateRoute'
import Layout from './components/layoutItems/layout';
import LoginComponent from './components/loginComponent';
import LoginComponentUser from './components/login/loginGeneralUserComponent';
import NotAuthorizedComponent from './components/notAuthorizedComponent';
import NotFoundComponent from './components/notFoundComponent';
import LogoutComponent from './components/logoutComponent';
import HomeComponent from './components/homeComponent';
import ReportComponent from './components/reportComponent';
import HistoryComponent from './components/historyComponent';
import SettingsComponent from './components/settingsComponent';
import LostPwdComponent from './components/lostPwdComponent';
import ApprovalsComponent from './components/approvalsComponent';
import OfferComponent from './components/offerComponent';
import SsoLoginComponent from './components/login/ssoLoginComponent';
import PersonalDataComponent from './components/layoutItems/personalData';

import 'moment/locale/sk';
import 'moment/locale/cs';
import 'moment/locale/de';

const onMissingTranslation = ({ translationId }) => `-${translationId}-`;
const momentLocaleMap = { "sk-SK": "sk", "cs-CZ": "cs", "en-US": "en-gb", "de-DE": "de" };

class App extends React.Component {
  constructor(props) {
    super(props);

    const defLang = getLanguage();
    Moment.locale(momentLocaleMap[defLang] || 'sk');
    this.props.fetchTranslations(defLang, true);
  }

  render() {
      return (
          <React.Fragment>
              <noscript>
                  You need to enable JavaScript to run this app.
              </noscript>
              <Layout>
                  <Route exact path='/' component={HomeComponent} />
                  <Switch>
                    <PrivateRoute path='/report' component={ReportComponent} />
                    <PrivateRoute path='/approvals' component={ApprovalsComponent} />
                    <PrivateRoute path='/history' component={HistoryComponent} />
                    <PrivateRoute path='/offer' component={OfferComponent} />
                    <PrivateRoute exact path='/settings' component={SettingsComponent} />
                    <Route path="/personal-data" component={PersonalDataComponent} />
                    <Route exact path="/login-sso" component={SsoLoginComponent} />
                    <Route exact path="/login" component={LoginComponent} />
                    <Route exact path="/login-user" component={LoginComponentUser} />
                    <Route exact path="/lost-password" component={LostPwdComponent} />
                    <Route exact path="/notAuthorized" component={NotAuthorizedComponent} />
                    <Route exact path="/notFound" component={NotFoundComponent} />
                    <Route exact path="/logout" component={LogoutComponent} />
                  </Switch>
              </Layout>
          </React.Fragment>
      );
  };
}

App.propTypes = {
  fetchTranslations: PropTypes.func.isRequired
};

export default withTranslation("translation")(connect(
  state => state.appContext,
  dispatch => bindActionCreators(appContextActions, dispatch)
)(App));