import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';
//import FinColumnOrderManager from './finStatements/finColumnOrderManager';
import HorizontalScrollControl from '../common/horizontalScrollControl';
import OldStyleSection from '../common/oldStyleSection';
import OldStyleTableWithHistory from '../common/oldStyleTableWithHistory';

import './finStatements.scss';
import { isNullOrUndefined } from '../../store/storeFunctions';

const ROW_TYPES = {
    data: "data",
    marking: "marking"
}

class FinStatementsData extends React.Component {
    
    render() {
        let ascending = true;
        //if (info.params != null && info.params.ascending != null) ascending = info.params.ascending;
        return (this.props.data === undefined || this.props.data === null ? null : <div>{this.renderContent(this.props.data, ascending)}</div>);
    }

    renderContent = (data, ascending) => {
        const hasAnyIfrsData = data.ifrs !== undefined && data.ifrs !== null && Object.keys(data.ifrs).some(key => data.ifrs[key].hasFinancialPositionRows || data.ifrs[key].hasProfitAndLossRows || data.ifrs[key].hasCashFlowRows);
        return (
            <>
                {/*<FinColumnOrderManager sectionId={this.props.sectionId} />*/}
                {data.hasAnyRows && <>
                    <OldStyleSection isOpen={!hasAnyIfrsData} isBold hasData name={this.props.t(`Report.FinancialStatements.SectionName${data.country.toLowerCase() === "cz" ? "" : "SK"}`)}>
                        {data.hasBalanceSheetRows ? (data.country.toLowerCase() === "cz"
                            ? this.renderCzBalanceSheet(data) : this.renderSkBalanceSheet(data)) : null}
                        {data.hasProfitLossRows ? (data.country.toLowerCase() === "cz"
                            ? this.renderCzProfitLoss(data) : this.renderSkProfitLoss(data, "profitLossStatementRows", "Report.FinancialStatements.ProfitLossStatement")) : null}
                    </OldStyleSection>
                </>}
                {!isNullOrUndefined(data.budgetContribStatements) && data.budgetContribStatements.hasAnyRows && <>
                    <OldStyleSection isOpen={!hasAnyIfrsData && !data.statements.hasAnyRows} isBold hasData name={this.props.t(`Report.FinancialBudgetContribStatements.SectionName`)}>
                        {data.budgetContribStatements.hasBalanceSheetRows ? this.renderSkBalanceSheet(data.budgetContribStatements) : null}
                        {data.budgetContribStatements.hasProfitLossRows ? this.renderSkProfitLoss(data.budgetContribStatements, "profitLossStatementRows","Report.FinancialBudgetContribStatements.ProfitLossStatementTogether") : null}
                        {data.budgetContribStatements.hasProfitLossMainRows ? this.renderSkProfitLoss(data.budgetContribStatements, "profitLossMainStatementRows", "Report.FinancialBudgetContribStatements.ProfitLossStatementMainActivity") : null}
                        {data.budgetContribStatements.hasProfitLossBusinessRows ? this.renderSkProfitLoss(data.budgetContribStatements, "profitLossBusinessStatementRows", "Report.FinancialBudgetContribStatements.ProfitLossStatementBusinessActivity") : null}
                    </OldStyleSection>
                </>}
            </>
        );
    }

    renderCzProfitLoss = (data) => {
        return (
            <div style={{ marginLeft: "2.5em", paddingTop: "0.25em" }}>
                <OldStyleSection isOpen isBold hasData={data.profitLossStatementRows.length > 0}
                    name={this.getCzSectionName("Report.FinancialStatements.ProfitLossStatement", data.statements[0].currency)}
                >
                    <div className="fin-stmt-scroll-wrapper" style={{ paddingTop: ".25em" }}>
                        <HorizontalScrollControl leftArrowMargin="17.7em">
                            <OldStyleTableWithHistory fixed={false} classNames={{ "fin-stmt-table-first": true }}
                                tableColumns={data.headerTypes.length + data.statements.length + 1} stripped
                                head={() => this.renderCzHeader(data.headerTypes, data.statements)}
                                actualData={this.createCzAssetLiabilitiesRowData(data.headerTypes, data.statements, data.profitLossStatementRows)}
                                actualRow={(r) => this.renderCzRow(r)}
                            />
                        </HorizontalScrollControl>
                    </div>
                    {data.statements.filter(s => s.isIfrs).length > 0
                        ? <div className="fin-stmt-ifrs-legend">*&nbsp;<Trans i18nKey="Report.FinancialStatements.IfrsLegend" /></div>
                        : null}
                </OldStyleSection>
            </div>
        );
    }

    renderCzBalanceSheet = (data) => {
        return (
            <div style={{ marginLeft: "2.5em", paddingTop: "0.25em" }}>
                <OldStyleSection isOpen isBold
                    name={this.getCzSectionName("Report.FinancialStatements.BalanceSheet", data.statements[0].currency)}
                    hasData={data.balanceSheetAssets.length > 0 || data.balanceSheetLiabilities.length > 0}
                >
                    <div style={{ paddingTop: ".25em" }}></div>
                    {this.renderCzBalanceSheetTable(data, true)}
                    {this.renderCzBalanceSheetTable(data, false)}
                    {data.statements.filter(s => s.isIfrs).length > 0
                        ? <div className="fin-stmt-ifrs-legend">*&nbsp;<Trans i18nKey="Report.FinancialStatements.IfrsLegend" /></div>
                        : null}
                </OldStyleSection>
            </div>
        );
    }

    renderCzBalanceSheetTable = (data, isAssets) => {
        return (
            <div className="fin-stmt-scroll-wrapper">
                <HorizontalScrollControl leftArrowMargin="17.7em">
                    <OldStyleTableWithHistory fixed={false} classNames={{ "fin-stmt-table-first": true }}
                        tableColumns={data.headerTypes.length + data.statements.length + 1} stripped
                        head={() => this.renderCzHeader(data.headerTypes, data.statements, isAssets)}
                        actualData={this.createCzAssetLiabilitiesRowData(data.headerTypes, data.statements, isAssets ? data.balanceSheetAssets : data.balanceSheetLiabilities)}
                        actualRow={(r) => this.renderCzRow(r)}
                    />
                </HorizontalScrollControl>
            </div>
        );
    }

    renderCzHeader = (headerTypes, statements, isAssets) => {
        return (
            <thead>
                {isAssets !== undefined && isAssets !== null && <tr>
                    <th colSpan={headerTypes.length + 1} className='col-sticky fin-stmt-w-10 right-shadowed' style={{ left: 0 }}>
                        <span style={{ textTransform: "uppercase", fontSize: "1.2em" }}><Trans i18nKey={`Report.FinancialStatements.${isAssets ? "Assets" : "Liabilities"}`} /></span>
                    </th>
                    {statements.map(s => <th style={{ width: "9.5em" }}></th>)}
                </tr>}
                <tr style={{ borderBottom: "none" }}>
                    {headerTypes.map((ht, index) => <th title={ht.name} className='col-sticky fin-stmt-w-5 fin-stmt-right' style={{ left: `${index * 5}em`/*, width: "6.5em"*/ }}></th>)}
                    <th className='col-sticky fin-stmt-w-10 right-shadowed side-header' style={{ left: `${headerTypes.length * 5}em` }}>
                        <Trans i18nKey="Report.FinancialStatements.PeriodFrom" />:
                    </th>
                    {statements.map(s => <th key={s.id} className="fin-stmt-right" style={{ width: "9.5em" }}>{s.startDate}</th>)}
                </tr>
                <tr style={{ borderTop: "none" }}>
                    {headerTypes.map((_, index) => <th className='col-sticky fin-stmt-w-5 fin-stmt-right' style={{ left: `${index * 5}em`/*, width: "6.5em"*/ }}></th>)}
                    <th className='col-sticky fin-stmt-w-10 right-shadowed side-header' style={{ left: `${headerTypes.length * 5}em` }}>
                        <Trans i18nKey="Report.FinancialStatements.PeriodTo" />:
                    </th>
                    {statements.map(s =>
                        <th key={s.id} className="fin-stmt-right" style={{ width: "9.5em" }}>
                            {s.endDate}
                            {s.isIfrs ? <span className="fin-stmt-type-cz">&nbsp;*</span> : null}
                        </th>
                    )}
                </tr>
            </thead>
        );
    }

    renderCzRow = (r) => {
        return (
            <tr className={classnames("fin-stmt-row", { [`level-${r.level}`]: r.type === ROW_TYPES.data, "marking": r.type === ROW_TYPES.marking })}>
                {r.data.map(d => <td key={d.key} className={d.className} style={{ left: (d.index !== undefined ? `${d.index * 5}em` : '') }}>{d.value}</td>)}
            </tr>
        );
    }

    renderSkProfitLoss = (data, profitLossDataName, sectionNameKey) => {
        return (
            <div style={{ marginLeft: "2.5em", paddingTop: "0.25em" }}>
                <OldStyleSection isOpen isBold hasData={data[profitLossDataName].length > 0}
                    name={this.props.t(sectionNameKey)}
                >
                    <div className="fin-stmt-scroll-wrapper" style={{ paddingTop: ".25em" }}>
                        <HorizontalScrollControl leftArrowMargin="17.7em">
                            <OldStyleTableWithHistory fixed={false} classNames={{ "fin-stmt-table-first": true }}
                                tableColumns={data.headerTypes.length + data.statements.length + 1} stripped
                                head={() => this.renderSkHeader(data.headerTypes, data.statements)}
                                actualData={data[profitLossDataName]}
                                actualRow={(r) => this.renderSkRow(r)}
                            />
                        </HorizontalScrollControl>
                    </div>
                    {data.statements.filter(s => s.isIncorrect === true).length > 0
                        ? <div className="fin-stmt-incorrect-legend">*&nbsp;<Trans i18nKey="Report.FinancialStatements.IncorrectLegend" /></div>
                        : null}
                </OldStyleSection>
            </div>
        );
    }

    renderSkBalanceSheet = (data) => {
        return (
            <div style={{ marginLeft: "2.5em", paddingTop: "0.25em" }}>
                <OldStyleSection isOpen isBold
                    name={this.props.t("Report.FinancialStatements.BalanceSheet")}
                    hasData={data.balanceSheetAssets.length > 0 || data.balanceSheetLiabilities.length > 0}
                >
                    {this.renderSkBalanceSheetTable(data, true)}
                    {this.renderSkBalanceSheetTable(data, false)}
                    {data.statements.filter(s => s.isIncorrect === true).length > 0
                        ? <div className="fin-stmt-incorrect-legend">*&nbsp;<Trans i18nKey="Report.FinancialStatements.IncorrectLegend" /></div>
                        : null}
                </OldStyleSection>
            </div>
        );
    }

    renderSkBalanceSheetTable = (data, isAssets) => {
        return (
            <div className="fin-stmt-scroll-wrapper">
                <HorizontalScrollControl leftArrowMargin="17.7em" >
                    <OldStyleTableWithHistory fixed={false} classNames={{ "fin-stmt-table-first": true }}
                        tableColumns={data.headerTypes.length + data.statements.length + 1} stripped
                        head={() => this.renderSkHeader(data.headerTypes, data.statements, isAssets)}
                        actualData={isAssets ? data.balanceSheetAssets : data.balanceSheetLiabilities}
                        actualRow={(r) => this.renderSkRow(r)}
                    />
                </HorizontalScrollControl>
            </div>
        );
    }

    renderSkHeader = (headerTypes, statements, isAssets) => {
        const isBalanceSheet = isAssets !== undefined && isAssets !== null;
        return (
            <thead>
                {isBalanceSheet && <tr>
                    <th className="col-sticky fin-stmt-w-10" style={{ left: "0" }}>
                        <span style={{ textTransform: "uppercase", fontSize: "1.2em" }}><Trans i18nKey={`Report.FinancialStatements.${isAssets ? "Assets" : "Liabilities"}`} /></span>
                    </th>
                    {headerTypes.map((_, index) => <th key={index} className={classnames("col-sticky fin-stmt-w-5", { "right-shadowed": (index === headerTypes.length - 1) })} style={{ left: `${10 + (index * 5)}em` }}></th>)}
                    {statements.map(s => <th key={`Head${s.id}`} style={{ width: "9.5em" }}></th>)}
                </tr>}
                <tr>
                    <th className="col-sticky fin-stmt-w-10" style={{ left: "0" }}><Trans i18nKey="Report.FinancialStatements.ItemName" /></th>
                    {headerTypes.map((ht, index) => <th key={ht.typeId} title={ht.name} className={classnames("col-sticky fin-stmt-w-5 fin-stmt-right", { "right-shadowed": (index === headerTypes.length - 1) })} style={{ left: `${10 + (index * 5)}em` }}>({ht.marking})</th>)}
                    {statements.map(s =>
                        <th key={s.id} className="fin-stmt-right" style={{ width: "9.5em" }}>
                            {!isBalanceSheet ? <>{s.startDate}<br /></> : null}
                            {s.endDate}<br />
                            ({s.currency.toUpperCase()}) ({headerTypes.find(o => o.type === s.type).marking})
                            {s.isIncorrect ? <span className="fin-stmt-type-sk">&nbsp;*</span> : null}
                        </th>
                    )}
                </tr>
            </thead>
        );
    }

    renderSkRow = (r) => {
        return (
            <tr key={r.rowId} className={`fin-stmt-row level-${r.rowLevel}`}>
                <td className="col-sticky fin-stmt-w-10" style={{ left: "0" }}>{r.description}</td>
                {r.types.map((rt, index) => <td key={rt.rowId} className={classnames("col-sticky fin-stmt-w-5 fin-stmt-right", { "right-shadowed": (index === r.types.length - 1) })} style={{ left: `${10 + (index * 5)}em` }}>{rt.value}</td>)}
                {r.values.map(rv => <td className={classnames("fin-stmt-value", { "incorrect": rv.incorrect, "inherit": !rv.incorrect })} key={rv.rowId}>{rv.value}</td>)}
            </tr>
        );
    }

    createCzAssetLiabilitiesRowData = (headerTypes, statements, items) => {
        return [
            {
                type: ROW_TYPES.marking, data: [
                    ...headerTypes.map((ht, index) => ({ key: ht.typeId, value: `(${ht.marking})`, className: "col-sticky fin-stmt-w-5", index: index })),
                    { key: "mk-desc", value: "", className: "col-sticky fin-stmt-w-10 right-shadowed", index: headerTypes.length },
                    ...statements.map(s => ({ key: s.rowId, value: `(${headerTypes.find(o => o.type === s.type).marking})`, className: "fin-stmt-right" }))
                ]
            },
            ...items.map(bs => ({
                type: ROW_TYPES.data, level: bs.rowLevel, data: [
                    ...bs.types.map((t, index) => ({ key: t.rowId, value: t.value, className: "col-sticky fin-stmt-w-5", index: index })),
                    { key: `desc-${bs.rowId}`, value: bs.description, className: "col-sticky fin-stmt-w-10 right-shadowed", index: bs.types.length },
                    ...bs.values.map(v => ({
                        key: v.rowId, value: v.value, className: classnames("fin-stmt-value", {
                            "incorrect": v.incorrect,
                            "ifrs": v.isIfrs,
                            "inherit": !v.incorrect && !v.isIfrs
                        })
                    }))
                ]
            }))
        ];
    }

    getCzSectionName = (key, currency) => {
        const sectionName = this.props.t(key);
        const currencySuffix = this.props.t("Report.Common.ThousandsShort", { currency: currency });
        return `${sectionName} (${currencySuffix})`;
    }
}

export default withTranslation("translation")(connect()(FinStatementsData));