import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ModalWindow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.handleClickClose = this.handleClickClose.bind(this);
    }

    handleClickClose(event) {
        if (event.isDefaultPrevented()) {
            return;
        }
        this.props.onClose();
        event.preventDefault();
    }

    render() {
        return (
            <div className={classnames("modal", { "is-active": this.props.isActive })}>
                <div className="modal-background"></div>
                <div className={classnames("modal-card", { "modal-width": this.props.width })}>
                    <header className="modal-card-head">
                        {this.props.hideHeaderClose ? <p className="modal-card-title center">{this.props.t(this.props.title)}</p> :
                            <>
                                <p className="modal-card-title">{this.props.t(this.props.title)}</p>
                                <button className="delete" aria-label="close" onClick={this.props.onClickCancel}></button>
                            </>
                        }
                    </header>
                    <section className="modal-card-body">
                        {this.props.children}
                    </section>
                    {!this.props.hideFooter && <footer className="modal-card-foot">
                        {this.props.successButton &&
                            <button className="button is-success" onClick={this.props.onClickSuccess}>{this.props.t(this.props.successButtonName)}</button>}
                        <button className="button" onClick={this.props.onClickCancel}>{this.props.t(this.props.cancelButtonName)}</button>
                    </footer>}
                </div>
            </div>
        );
    }
}

ModalWindow.propTypes = {
    isActive: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onClickSuccess: PropTypes.func,
    onClickCancel: PropTypes.func.isRequired,
    successButtonName: PropTypes.string,
    cancelButtonName: PropTypes.string,
    successButton: PropTypes.bool
}

export default withTranslation("translation")(ModalWindow);