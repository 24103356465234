import React from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router';

import ReportSearchFormComponent from './report/reportSearchFormComponent';
import ReportResultComponent from './report/reportResultComponent';
import MunicipalityResultComponent from './report/municipalities/municipalityResultComponent';
import ReportLayout from './layoutItems/reportLayout';
import { isOnPath, hasPermission } from '../store/apiWrapper';


class ReportComponent extends React.Component {
    render() {
        if (hasPermission(null, "UserRatingMSP") || hasPermission(null, "UserRatingCalc") || window.location.pathname === `${this.props.match.url}/result`) {
            return (
                <React.Fragment>
                    {isOnPath({ regex: /^((\/|)report)$/i }) && <ReportLayout></ReportLayout>}
                    <Switch>
                        <Route path={`${this.props.match.url}/search/municipality`} component={MunicipalityResultComponent} />
                        <Route path={`${this.props.match.url}/search`} component={ReportSearchFormComponent} />
                        <Route path={`${this.props.match.url}/result`} component={ReportResultComponent} />
                    </Switch>
                </React.Fragment>
            );
        }
        else return (<Redirect to={`/login?returnUrl=${encodeURIComponent(this.props.match.url)}`} />);
    }
}
export default withRouter(ReportComponent);
