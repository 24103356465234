import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames'
import { bindActionCreators } from 'redux';
import { appContextActions } from '../store/appContextStore'
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseQuery, isObjectPathDefined, apiPost } from '../store/apiWrapper';

import InputControl from './common//formFields/inputControl';

class LostPwdComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '', email: '', token: '', loading: false, error: false, stage: 1, completed: false
        };

        const q = parseQuery(window.location.search);
        if (isObjectPathDefined(q, 'u') && isObjectPathDefined(q, 'rpt')) {
            this.state.loading = true;
            this.resetPwdConfirm(q.u, q.rpt);
            this.state.stage = 2;
        }
    }

    changeEvent = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    resetPwdConfirm = (token, user) => {
        apiPost('api/account/confirm-reset-pwd', undefined, { token, user },
            (res) => this.setState({ error: !res.data.success, message: res.data.errorMessage }),
            (err) => this.setState({ error: true, message: err }),
            () => this.setState({ loading: false, completed: true }));
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true, error: false, message: undefined });
        apiPost('api/account/reset-pwd', undefined, { userName: this.state.username, email: this.state.email },
            (res) => this.setState({ error: !res.data.success, message: res.data.errorMessage }),
            (err) => this.setState({ error: true, message: err }),
            () => this.setState({ loading: false, completed: true }));
    }

    renderStageOne = () => {
        if (this.state.completed) {
            return (
                <div className="column is-half">
                    <div className={classnames({ 'notification': true, 'is-primary': !this.state.error, 'is-danger': this.state.error })} dangerouslySetInnerHTML={{ __html: this.state.message }}></div>
                </div>
            );
        }

        return (
            <div className="card" style={{ minWidth: "600px" }}>
                <header className="card-header">
                    <p className="card-header-title">{this.props.t('PwdReset.FormCaption')}</p>
                </header>
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            <table className="table full-width no-border">
                                <tr>
                                    <td style={{ textAlign: "right", whiteSpace: "nowrap" }}><label className="label">{this.props.t('PwdReset.Username')}</label></td>
                                    <td style={{ width: "100%" }}><InputControl value={this.state.username} type="text" fieldName="username" onChange={this.changeEvent} disabled={this.state.loading} /></td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", whiteSpace: "nowrap" }}><label className="label">{this.props.t('PwdReset.UserEmail')}</label></td>
                                    <td style={{ width: "100%" }}><InputControl value={this.state.email} type="email" fieldName="email" onChange={this.changeEvent} disabled={this.state.loading} /></td>
                                </tr>
                                <tr>
                                    <td colspan={2}>
                                        <p className="control">
                                            {!this.state.loading && (<input type="submit" value={this.props.t('PwdReset.BtnReset')} className="is-success button is-pulled-right" disabled={this.state.loading} />)}
                                            {this.state.loading && (<span className="is-info button is-pulled-right"><FontAwesomeIcon icon="cog" spin /></span>)}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>

            </div>
        );
    };

    renderStageTwo = () => {
        return (
            <div className="column is-half">
                {this.state.completed && <div className={classnames({ 'notification': true, 'is-primary': !this.state.error, 'is-danger': this.state.error })} dangerouslySetInnerHTML={{ __html: this.state.message }}></div>}
                {!this.state.completed && this.state.loading && (<span>processing <FontAwesomeIcon icon="cog" spin /></span>)}
            </div>
        );
    }

    render() {
        return (
            <section className="section">
                <div className="columns is-centered">
                    {this.state.stage === 2 && this.renderStageTwo()}
                    {this.state.stage !== 2 && this.renderStageOne()}
                </div>
            </section>
        );
    }
}

export default withTranslation("translation")(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(LostPwdComponent));
