import { getLanguage, getAuthorization } from '../../store/apiWrapper';

export const url = {
    downloadAllInputFiles: (allInputFilesId) => {
        return `api/history/download/all-input-files/${allInputFilesId}?lang=${getLanguage()}&access_token=${getAuthorization(false)}`;
    },
    downloadTemplate: (ico, finStatementsIds) => {
        return `api/report/download-template?access_token=${getAuthorization(false)}&finStatementsIds=${finStatementsIds}&ico=${ico}`;
    },
    downloadPdf: (guid) => {
        return `api/report/download/pdf/${guid}?lang=${getLanguage()}&access_token=${getAuthorization(false)}`;
    },
    downloadInputFile: (guid) => {
        return `api/report/download/input-file/${guid}?access_token=${getAuthorization(false)}`;
    }
}