import React from 'react'
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/lib/connect/connect';
import { isNullOrUndefined } from '../../store/storeFunctions';
import { bindActionCreators } from 'redux';
import ReactHtmlParser from 'react-html-parser';

import { getLanguage, getAuthorization, parseQuery, hasPermission, isObjectPathDefined } from '../../store/apiWrapper';
import { appContextActions } from '../../store/appContextStore';
import Loading from '../common/loading/loading';
import ModalWindow from '../common/modal/modalWindow';
import Message from '../common/message/message';
import ReportPermissionsFormComponent from './reportPermissionsFormComponent';
import { url } from '../common/urlHelper';

class ReportResultComponent extends React.Component {
    state = { showModal: false, approvals: false, comment: "", publishAboveRating: [], partners: [], domains: false }

    componentDidMount() {
        const qs = parseQuery(window.location.search);
        if (window.location.pathname === "/approvals/result") {
            this.setState({ approvals: true });
        }
        else {
            this.setState({ approvals: false });
        }
        if (!hasPermission(null, "UserRatingMSP") && !isNullOrUndefined(qs.id) && qs.id !== "null" && !isObjectPathDefined(this.props, 'report.error') && !isObjectPathDefined(this.props, 'report.reportResult.error')) {
            this.props.getHistoryReport(qs.id);
        }
    }

    componentDidUpdate() {
        if (hasPermission(null, "UserCCB")) {
            if (!this.props.report.isLoading && !this.state.domains && !(isNullOrUndefined(this.props.report) && isNullOrUndefined(this.props.report.reportResult))) {
                this.props.loadDropdownItems(this.props.report.reportResult.data.type, this.props.report.reportResult.data.country)
                this.props.loadRatingRange([`${this.props.report.reportResult.data.type}${this.props.report.reportResult.data.country}`]);
                this.setState({ domains: true });
            }

        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderError(this.props.report)}
                {!(isNullOrUndefined(this.props.report) || isNullOrUndefined(this.props.report.reportResult)) && isNullOrUndefined(this.props.report.reportResult.error) && this.renderResult(this.props.report.reportResult)}
                {this.props.report.isLoading && this.renderLoading()}
            </React.Fragment>
        )
    }

    renderSuccesCreatedRating = () => {
        return (
            <div>
                Report created
            </div>
        )
    }

    renderError = (sr) => {
        if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.error)) {
            return (<Message error={sr.error.data.error} />);
        }
        else if (!isNullOrUndefined(sr) && !isNullOrUndefined(sr.reportResult) && !isNullOrUndefined(sr.reportResult.error)) {
            return (<Message error={sr.reportResult.error} />);
        }
    }

    renderLoading = () => {
        return (
            <Loading />
        )
    }

    approvals = (approve) => {
        this.props.approvalsRating(this.props.report.reportResult.data.guid, approve, this.state.comment);
    }

    changeEvent = (event) => {
        this.setState({ [event.target.name]: event.target[[118, 97, 108, 117, 101].map(x => String.fromCharCode(x)).join('')] });
    }


    showHideModal = () => {
        this.setState({ showModal: (this.state.showModal === false) });
    }
    setModal = (report) => {
        this.setState({ partners: report.partners, publishAboveRating: isNullOrUndefined(report.publishAboveRating) ? [this.props.ratingRange.ratingRange[this.props.ratingRange.ratingRange.length - 1]] : [report.publishAboveRating] })
        this.showHideModal()
    }

    updateRatingPublish = () => {
        this.props.updatePublishPermissions(this.props.report.reportResult.data.guid, this.state.partners, this.state.publishAboveRating[0]);
        this.showHideModal()
    }

    renderResult = (res) => {
        if (hasPermission(null, "UserRatingMSP")) {
            return this.renderSuccesCreatedRating();
        }
        if (isObjectPathDefined(this.props, 'report.error') && isObjectPathDefined(this.props, 'report.reportResult.error')) {
            return null;
        }
        return (
            <div className="columns work-container">
                <div className="column left-navbar-container">
                    <div className="box">
                        <aside className="is-narrow-mobile has-text-centered is-fullheight">
                            <p className="menu-label">
                                {this.props.t('IRating.Report.LeftMenu.Title')}
                            </p>
                            <ul className="menu-list">
                                {!isNullOrUndefined(res.data.html) &&
                                    <li><div className="has-text-centered is-primary">
                                        <p>{this.props.t('IRating.Report.PdfFile')}</p>
                                        <a className="button is-fullwidth" target="_blank" href={url.downloadPdf(this.props.report.reportResult.data.guid)}>{this.props.t('IRating.Common.Button.Download')}</a>
                                    </div>
                                    </li>
                                }
                                <li><div className="has-text-centered is-primary">
                                    <p>{this.props.t('IRating.Report.InputFile')}</p>
                                    <a className="button is-fullwidth" target="_blank" href={url.downloadInputFile(this.props.report.reportResult.data.guid)}>{this.props.t('IRating.Common.Button.Download')}</a>
                                </div>
                                </li>
                                {(hasPermission(null, "UserCCB") && !isNullOrUndefined(res.data.html)) &&
                                    <li>
                                        <div className="has-text-centered is-primary">
                                            <p>{this.props.t('IRating.Report.EditPublish')}</p>
                                            <button className="button is-fullwidth" onClick={() => this.setModal(res.data)}>{this.props.t('IRating.Common.Button.Edit')}</button>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </aside>
                    </div>
                </div>
                <div className="column right-body-container is-three-quarters">
                    <div className="box">
                        {hasPermission(null, "UserCCB") &&
                            <ModalWindow isActive={this.state.showModal} title='IRating.Report.EditPublish.Title'
                                onClickCancel={() => this.showHideModal()} cancelButtonName='IRating.Common.Button.Cancel'
                                successButton={true} successButtonName='IRating.Common.Button.Save' onClickSuccess={() => this.updateRatingPublish()}>
                                <div className="body-edit-permissions">
                                    <ReportPermissionsFormComponent publishAboveRatingValue={this.state.publishAboveRating}
                                        publishAboveRatingOnChange={publishAboveRating => this.setState({ publishAboveRating })}
                                        partnersValue={this.state.partners} partnersOnChange={partners => this.setState({ partners })} />
                                </div>
                            </ModalWindow>
                        }
                        {!isNullOrUndefined(res.data.html) ?
                            <div>
                                {ReactHtmlParser(res.data.html)}
                            </div>
                            : (res.data.html === null ? <Message error={{ code: 400, message: `${this.props.t('IRating.Error.Message.RatingRejected')} ${res.data.comment}` }} />
                                : null)
                        }
                        {this.state.approvals &&
                            <div className="box">
                                <div className="field">
                                    <label className="label">{this.props.t('IRating.Note')}</label>
                                    <div className="control">
                                        <textarea value={this.state.comment} name="comment" onChange={this.changeEvent} className="textarea" placeholder={this.props.t('IRating.Note')}></textarea>
                                    </div>
                                </div>
                                <div className="field is-grouped is-grouped-centered">
                                    <div className="control">
                                        <button className="button is-primary" onClick={(e) => { this.approvals(true) }}>
                                            {this.props.t('IRating.Common.Button.Approve')}
                                        </button>
                                    </div>
                                    <div className="control">
                                        <button className="button is-danger" onClick={(e) => { this.approvals(false) }}>
                                            {this.props.t('IRating.Common.Button.Reject')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="column">
                </div>
            </div>
        );
    }
}

export default withTranslation("translation")(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(ReportResultComponent))