import { hasPermission } from '../../store/apiWrapper';

function hasRatingTypePermission(report) {
    switch (report.toLowerCase()) {
        case "smecz": return hasPermission(null, "SmeCzRating");
        case "corpcz": return hasPermission(null, "CorpCzRating");
        case "entrepreneurcz": return hasPermission(null, "EntrepreneurCzRating");
        case "smesk": return hasPermission(null, "SmeSkRating");
        case "nonprofitorganizationcz": return hasPermission(null, "NonProfitOrganizationCzRating");
        case "housingcooperativecz": return hasPermission(null, "HousingCooperativeCzRating");
        case "municipalitycz": return hasPermission(null, "MunicipalityCzRating");
        case "municipalitysk": return hasPermission(null, "MunicipalitySkRating");
        default: return false;
    }
}

export {hasRatingTypePermission};