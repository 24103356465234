import React from 'react';
import { Route, withRouter, Switch } from 'react-router';

import OfferResultComponent from './offer/offerResult';
import OfferDetailComponent from './offer/offerDetail';


class OfferComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path={`${this.props.match.url}/:offerName`} component={OfferDetailComponent} />
                    <Route path={`${this.props.match.url}`} component={OfferResultComponent} />
                </Switch>
            </React.Fragment>
        );
    }
}
export default withRouter(OfferComponent);
