import React from "react";

const SvgIRating = props => (
    <svg viewBox="0 0 161.25 47.68">
        <g id="Vrstva_2" data-name="Vrstva 2">
            <g id="Livello_1" data-name="Livello 1">
                <path className="cls-1" d="M2.6,38H6.23a3.5,3.5,0,0,1,1.4.25,2,2,0,0,1,.9.72,2.34,2.34,0,0,1,.37,1.15,4.81,4.81,0,0,1-.18,1.53A4.94,4.94,0,0,1,7.31,44a3.81,3.81,0,0,1-1.38.86,5.87,5.87,0,0,1-1.74.23H2.57l-.69,2.59H0Zm1.47,1.54L3,43.57H4.13a3.52,3.52,0,0,0,1.25-.2,1.88,1.88,0,0,0,.85-.66,3.35,3.35,0,0,0,.55-1.23,2.59,2.59,0,0,0,.12-1.17.81.81,0,0,0-.45-.62,2.59,2.59,0,0,0-1.09-.18Z" />
                <path className="cls-1" d="M17.29,41.53A5,5,0,0,1,16.62,43a4.77,4.77,0,0,1-1.07,1.17,5,5,0,0,1-1.39.75,5.15,5.15,0,0,1-1.63.25A4,4,0,0,1,11,45a2.37,2.37,0,0,1-1-.75A2.5,2.5,0,0,1,9.58,43a4.21,4.21,0,0,1,.14-1.5A5.15,5.15,0,0,1,10.39,40a4.5,4.5,0,0,1,1.08-1.18,4.59,4.59,0,0,1,1.41-.76,5.09,5.09,0,0,1,1.62-.26A4,4,0,0,1,16,38.1a2.47,2.47,0,0,1,1,.75A2.41,2.41,0,0,1,17.43,40,4.19,4.19,0,0,1,17.29,41.53Zm-5.59,0a2.71,2.71,0,0,0-.09,1.17,1.09,1.09,0,0,0,.42.74,1.51,1.51,0,0,0,.89.25,2.31,2.31,0,0,0,1-.25,2.49,2.49,0,0,0,.82-.74,3.7,3.7,0,0,0,.53-1.17,2,2,0,0,0-.05-1.59,1.26,1.26,0,0,0-1.18-.56,2.12,2.12,0,0,0-1.47.57A3,3,0,0,0,11.7,41.53Z" />
                <path className="cls-1" d="M24,40.23,21.2,45.09H19.11L18.53,38h2l.24,5,2.89-5h2l.24,5,2.91-5h1.93L26.4,45.09H24.23Z" />
                <path className="cls-1" d="M32.78,45.09a2.86,2.86,0,0,1-2.44-.9,3.05,3.05,0,0,1-.2-2.66A5.61,5.61,0,0,1,30.78,40a4,4,0,0,1,2.29-1.81A5.2,5.2,0,0,1,34.69,38h2.75L37,39.45H34.55a2.86,2.86,0,0,0-1.41.27,1.74,1.74,0,0,0-.71,1.07h4.25l-.4,1.49H32a1.66,1.66,0,0,0,0,.87.56.56,0,0,0,.44.37,4.12,4.12,0,0,0,1,.09h2.51l-.4,1.48Z" />
                <path className="cls-1" d="M39.05,38h3.16a3,3,0,0,1,1.14.17.79.79,0,0,1,.47.57,2.28,2.28,0,0,1-.09,1.06L43.41,41h-1.8l.25-.92a1.17,1.17,0,0,0,0-.46.26.26,0,0,0-.19-.19,1.59,1.59,0,0,0-.5,0h-.66L39,45.09H37.14Z" />
                <path className="cls-1" d="M46.73,45.09a2.87,2.87,0,0,1-2.44-.9,3.06,3.06,0,0,1-.19-2.66A5.92,5.92,0,0,1,44.73,40,3.94,3.94,0,0,1,47,38.2,5.18,5.18,0,0,1,48.64,38h2.75L51,39.45H48.51a2.9,2.9,0,0,0-1.42.27,1.73,1.73,0,0,0-.7,1.07h4.25l-.4,1.49H46a1.63,1.63,0,0,0,0,.87.56.56,0,0,0,.44.37,4,4,0,0,0,1,.09h2.5l-.4,1.48Z" />
                <path className="cls-1" d="M57.69,45.09H54.08a3.5,3.5,0,0,1-1.4-.25,1.93,1.93,0,0,1-.9-.73A2.24,2.24,0,0,1,51.41,43a4.75,4.75,0,0,1,.18-1.52A4.94,4.94,0,0,1,53,39.06a3.72,3.72,0,0,1,1.37-.85A5.54,5.54,0,0,1,56.11,38h1.61l.69-2.58h1.88Zm-1.47-1.52,1.09-4.06H56.18a2.8,2.8,0,0,0-1.73.46,3.64,3.64,0,0,0-1.05,2.79.86.86,0,0,0,.45.62,2.42,2.42,0,0,0,1.09.19Z" />
                <path className="cls-1" d="M69.33,35.39,68.64,38h1.62a4.46,4.46,0,0,1,1.62.24,1.75,1.75,0,0,1,.91.85,3.22,3.22,0,0,1,.14,2.38A5.48,5.48,0,0,1,72.29,43a4.44,4.44,0,0,1-1,1.16,4.24,4.24,0,0,1-1.3.72,4.51,4.51,0,0,1-1.53.25H64.85l2.6-9.7Zm0,4.12H68.23l-1.09,4.06h1.3a3.1,3.1,0,0,0,1.18-.19,1.77,1.77,0,0,0,.79-.62,4,4,0,0,0,.51-1.18A1.91,1.91,0,0,0,70.86,40,1.83,1.83,0,0,0,69.38,39.51Z" />
                <path className="cls-1" d="M76.87,43.52h1.51L79.87,38h1.88L79.89,44.9a4.63,4.63,0,0,1-.7,1.55,2.8,2.8,0,0,1-1.13.92,3.9,3.9,0,0,1-1.6.31H72.77l.39-1.45h3.37a1.52,1.52,0,0,0,.9-.26,1.37,1.37,0,0,0,.5-.76l0-.12H76.29a4.19,4.19,0,0,1-1.64-.26,1.22,1.22,0,0,1-.71-.88,3.26,3.26,0,0,1,.12-1.59L75.24,38h1.87L76,42.21a3.46,3.46,0,0,0-.13.67.6.6,0,0,0,.09.39.54.54,0,0,0,.31.2A3.24,3.24,0,0,0,76.87,43.52Z" />
                <path className="cls-1" d="M93.38,37.05a2.86,2.86,0,0,0-1.47.36,3,3,0,0,0-1.06,1.11,7.5,7.5,0,0,0-.77,2,6.08,6.08,0,0,0-.24,1.37,1.71,1.71,0,0,0,.18.89,1.05,1.05,0,0,0,.6.49,3.1,3.1,0,0,0,1.06.15H95l-.46,1.69H91.13a6.62,6.62,0,0,1-1.63-.16,2.53,2.53,0,0,1-1.17-.69c-.78-.79-.93-2.07-.46-3.83a9.6,9.6,0,0,1,.88-2.24A5.46,5.46,0,0,1,90,36.61a4.62,4.62,0,0,1,1.67-.92,6.94,6.94,0,0,1,2.11-.3h3.39l-.44,1.66Z" />
                <path className="cls-1" d="M97.63,38h3.16a3,3,0,0,1,1.14.17.81.81,0,0,1,.48.57,2.45,2.45,0,0,1-.1,1.06L102,41H100.2l.24-.92a1,1,0,0,0,0-.46.25.25,0,0,0-.19-.19,1.56,1.56,0,0,0-.49,0h-.66L97.6,45.09H95.72Z" />
                <path className="cls-1" d="M104.15,45.09h-2L104.09,38h2Zm2.13-7.93h-2l.48-1.77h2Z" />
                <path className="cls-1" d="M110.48,35.39,109.79,38h1.62a4.37,4.37,0,0,1,1.61.24,1.77,1.77,0,0,1,.92.85,3.22,3.22,0,0,1,.13,2.38,5.78,5.78,0,0,1-.63,1.52,4.47,4.47,0,0,1-1,1.16,4.08,4.08,0,0,1-1.29.72,4.56,4.56,0,0,1-1.54.25H106l2.6-9.7Zm0,4.12h-1.15l-1.08,4.06h1.29a3.16,3.16,0,0,0,1.19-.19,1.8,1.8,0,0,0,.78-.62,3.51,3.51,0,0,0,.51-1.18A1.84,1.84,0,0,0,112,40,1.81,1.81,0,0,0,110.52,39.51Z" />
                <path className="cls-1" d="M116.42,45.09h-2L116.36,38h2Zm2.13-7.93h-2l.48-1.77h2Z" />
                <path className="cls-1" d="M118.58,43.61h4a1.68,1.68,0,0,0,.81-.17.84.84,0,0,0,.39-.53.48.48,0,0,0-.11-.53,1.15,1.15,0,0,0-.72-.17h-1.63a5.61,5.61,0,0,1-1.11-.08,1.35,1.35,0,0,1-.66-.3.88.88,0,0,1-.28-.62,2.87,2.87,0,0,1,.13-1,3.29,3.29,0,0,1,.61-1.26,2.4,2.4,0,0,1,1-.73,4,4,0,0,1,1.47-.24h4l-.39,1.48h-3.32a7.09,7.09,0,0,0-.76,0,1,1,0,0,0-.46.18.81.81,0,0,0-.26.43.47.47,0,0,0,.08.49,1,1,0,0,0,.64.15h1.7a2.1,2.1,0,0,1,1.65.54,1.66,1.66,0,0,1,.18,1.58,3.25,3.25,0,0,1-1,1.62,2.62,2.62,0,0,1-.76.46,2.31,2.31,0,0,1-.8.15h-4.86Z" />
                <path className="cls-2" d="M10.8,30.15H4.46L10.3,8h6.34ZM17.3,5.5H11L12.41,0h6.34Z" />
                <path className="cls-2" d="M24.67,0H40.82a15,15,0,0,1,5.86,1,4.8,4.8,0,0,1,2.83,3,9.35,9.35,0,0,1-.18,5.15,12.21,12.21,0,0,1-3.11,5.67,10.48,10.48,0,0,1-5.43,2.83l4.46,12.59H37.87L34.12,18.29H26.4L23.27,30.15H16.72ZM37.85,5.37h-8l-2,7.59h8a10.62,10.62,0,0,0,4.67-.8,4.42,4.42,0,0,0,2.15-3,3.49,3.49,0,0,0,0-2.35,2.23,2.23,0,0,0-1.59-1.13A13.66,13.66,0,0,0,37.85,5.37Z" />
                <path className="cls-2" d="M54.69,8H67.08q4.71,0,6.53,2.07c1.21,1.38,1.45,3.45.73,6.19L70.68,30.15H54.44c-2.28,0-3.88-.56-4.82-1.66s-1.13-2.68-.59-4.72a10,10,0,0,1,2-4.11,7.74,7.74,0,0,1,3.53-2.25,18.23,18.23,0,0,1,5.43-.7H68l.14-.55c.37-1.37.2-2.3-.49-2.81s-2-.76-4-.76H53.49ZM58.07,25.7h7.57l1.21-4.58H59.72a6.68,6.68,0,0,0-3,.53,2.91,2.91,0,0,0-1.39,2q-.33,1.26.3,1.68A5,5,0,0,0,58.07,25.7Z" />
                <path className="cls-2" d="M79.64,8h4.27L86,0h6L90,8h6.39l-1.16,4.41H88.79l-2.36,8.94a7.49,7.49,0,0,0-.32,2.55,1.74,1.74,0,0,0,.82,1.38,4.48,4.48,0,0,0,2.25.44h2.48L90.5,30.15H85a7,7,0,0,1-3.52-.79A3.72,3.72,0,0,1,79.71,27a7.48,7.48,0,0,1,.19-3.79l2.85-10.79H78.48Z" />
                <path className="cls-2" d="M101.28,30.15H94.94L100.78,8h6.34ZM107.79,5.5h-6.35L102.9,0h6.34Z" />
                <path className="cls-2" d="M113,8h11.37q5.13,0,6.86,1.85t.57,6.25l-3.7,14h-6l3.51-13.3a7.75,7.75,0,0,0,.36-2.44,1.33,1.33,0,0,0-.82-1.16,6.43,6.43,0,0,0-2.49-.35h-4.85l-4.55,17.25h-6.05Z" />
                <path className="cls-2" d="M155.62,29.35a14.66,14.66,0,0,1-2.21,4.93,8.69,8.69,0,0,1-3.61,2.93,12.64,12.64,0,0,1-5.16,1H132.79L134,33.68h10.82a4.78,4.78,0,0,0,2.89-.82,4,4,0,0,0,1.55-2.34l.09-.37H144.4a23.35,23.35,0,0,1-4.87-.4,5.62,5.62,0,0,1-3.07-2A7.44,7.44,0,0,1,134.93,24a12.51,12.51,0,0,1,.39-4.85,15.9,15.9,0,0,1,2.16-4.8A15.06,15.06,0,0,1,141,10.62a11.53,11.53,0,0,1,4-2A20.74,20.74,0,0,1,150.24,8h11Zm-8.23-3.78h3.14l3.38-12.81h-3.14a28.06,28.06,0,0,0-2.94.12,5.91,5.91,0,0,0-2,.59,7.05,7.05,0,0,0-2.64,2.19,10.61,10.61,0,0,0-1.55,3.47,9.44,9.44,0,0,0-.37,3.13,3,3,0,0,0,.81,2,4.25,4.25,0,0,0,2,1A14.56,14.56,0,0,0,147.39,25.57Z" />
            </g>
        </g>
    </svg>
);

export default SvgIRating;
