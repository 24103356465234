import React from 'react'
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/lib/connect/connect';
import { isNullOrUndefined } from '../../store/storeFunctions';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { MultiSelect } from 'react-sm-select';
import Select from 'react-select';
import classnames from 'classnames'
import { writeErr } from '../common/logger';

import { apiGet, parseQuery, redirectTo, createQs, hasPermission, apiPost, getAuthorization } from '../../store/apiWrapper';
import { hasRatingTypePermission } from '../common/ratingTypePermission'
import { appContextActions } from '../../store/appContextStore';
import FieldHorizontal from '../common/formFields/fieldHorizontal';
import ReportLayout from '../layoutItems/reportLayout';
import Loading from '../common/loading/loading'
import InputControl from '../common/formFields/inputControl';
import ButtonControl from '../common/formFields/buttonControl';
import { COUNTRY_TYPES, REPORT_TYPES } from '../common/enums'
import { toastMessage } from '../common/message/message';
import ReportPermissionsFormComponent from './reportPermissionsFormComponent';
import ModalWindow from '../common/modal/modalWindow';
import FinStatementsData from './finStatementsData';
import { url } from '../common/urlHelper';

const initialState = (report, nutsCode, subjectType) => {
    return ({
        progress: 0, fileName: "", name: '', email: "", partners: [],
        ico: '', nutsCode: nutsCode, isMonitoredClient: false,
        postCode: "", address: "", city: "", naceCode: [], file: {}, filePath: "", subjectType: subjectType, publishAboveRating: [], report: report, errors: {},
        financialStatementsSumary: null, IsRatingSkReport: false, calculationData: "", showModalEdit: false, showModalInsertFile: false, showModalView: false,
        statements: [], selectedStatements: [], finStatements: null, isLoadingStatementsData: false, subject: null, availableStatements: []
    });
}

class ReportSearchFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(parseQuery(window.location.search).type, [], []);
    }

    componentDidMount() {
        const q = parseQuery(window.location.search);
        this.loadDropDown(q);
    }

    componentDidUpdate(prevProps, prevState) {
        const q = parseQuery(window.location.search);
        if (!this.props.domains.isLoading && (q.type !== this.props.domains.report || q.country !== this.props.domains.country) && this.props.domains.naceCodes.length > 0 && this.props.domains.nutsCodes.length > 0 && this.props.domains.subjectTypes.length > 0) {
            this.setState(initialState(q.type, [this.props.domains.nutsCodes[0].code], [this.props.domains.subjectTypes[0].code]));
            if (q.country !== this.props.domains.country) {
                this.setState({ naceCode: [], nutsCode: [], subject: null });
            }
            if (q.type === REPORT_TYPES.municipality) {
                this.setState({ nutsCode: [""] });
            }
            this.loadDropDown(q);
        }
        else if (q.type !== REPORT_TYPES.municipality) {
            if (!this.props.ratingRange.isLoading && this.props.ratingRange.ratingRange.length > 0 && this.state.publishAboveRating.length === 0) {
                this.setState({ publishAboveRating: [this.props.ratingRange.ratingRange[this.props.ratingRange.ratingRange.length - 1]] });
            }
            if (!this.props.domains.isLoading && this.state.nutsCode.length === 0 && this.props.domains.nutsCodes.length > 0) {
                this.setState({ nutsCode: [this.props.domains.nutsCodes[0].code] });
            }
            if (!this.props.domains.isLoading && this.state.subjectType.length === 0 && this.props.domains.subjectTypes.length > 0) {
                this.setState({ subjectType: [this.props.domains.subjectTypes[0].code] });
            }
        }
    }

    loadDropDown(q) {
        this.props.loadDropdownItems(q.type, q.country);
        this.props.loadRatingRange([`${q.type}${q.country}`]);
    }

    parseQuery() {
        const q = parseQuery(window.location.search);
        const type = (q.type || '').toLowerCase();
        const country = (q.country || '').toLowerCase();
        return [type, country]
    }

    changeEvent = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    changeEventCheckbox = (event) => {
        this.setState({ [event.target.name]: (event.target.value === 'false') });
    }

    handleMunicipalitySubmit = (event, type, country) => {
        event.preventDefault();
        redirectTo(`/report/search/${type}?${createQs({ name: this.state.name, ico: this.state.ico, nutsCode: this.state.nutsCode[0], country, monitored: this.state.isMonitoredClient })}`);
    }

    handleSubmit = (event, type, country, file = false) => {
        let errors = {};
        errors = this.validateIco(errors);
        if (isNullOrUndefined(this.state.name) || this.state.name.length < 1) {
            errors.name = this.props.t('Common.RequiredField');
        }
        if (hasPermission(null, "UserRatingMSP")) {
            if (isNullOrUndefined(this.state.email) || this.state.email.length < 1) {
                errors.email = this.props.t('Common.RequiredField');
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) {
                errors.email = this.props.t('Common.InvalidEmail');
            }
        }
        if ((type === REPORT_TYPES.sme || type === REPORT_TYPES.corp || type === REPORT_TYPES.entrepreneur) && (isNullOrUndefined(this.state.naceCode) || this.state.naceCode.length < 1)) {
            errors.naceCode = this.props.t('Common.RequiredField');
        }
        if ((!this.isSmeSk(type, country) || file) && ((isNullOrUndefined(this.state.file) || isNullOrUndefined(this.state.fileName) || this.state.fileName.length < 1))) {
            errors.file = this.props.t('Common.RequiredField');
        }
        if(this.isSmeSk(type, country) && file && isNullOrUndefined(errors.file)){
            this.showHideModalInsertFile();
        }

        this.setState({ errors: errors });
        event.preventDefault();
        if (Object.keys(errors).length === 0) {
            this.props.createReport(type,
                {
                    file: this.state.file, partners: this.state.partners, publishAboveRating: this.state.publishAboveRating[0], subject: {
                        ico: this.state.ico, naceCode: this.state.naceCode[0],
                        nutsCode: this.state.nutsCode[0], subjectType: this.state.subjectType[0], email: this.state.email, address: this.state.address, postCode: this.state.postCode, city: this.state.city,
                        name: this.state.name, financialStatementsSumary: this.state.financialStatementsSumary
                    }
                }, country, this.state.isMonitoredClient);
        }
        if(this.isSmeSk(type, country) && file && isNullOrUndefined(errors.file)){
            this.setState({progress: 0, fileName: "", file: {}, filePath: ""});
        }
    }

    renderLoading = () => {
        return (
            <Loading />
        )
    }

    render() {
        var [type, country] = this.parseQuery();
        if (!hasRatingTypePermission(`${type}${country}`)) {
            return (<Redirect to={`/notAuthorized`} />);
        }
        return (
            <div>

                {<ReportLayout type={type} country={country}>
                    <div className="container is-centered columns">
                        {type === REPORT_TYPES.municipality && this.renderMunicipalitySearch(type, country)}
                        {type !== REPORT_TYPES.municipality && this.renderSearch(type, country)}
                    </div>
                </ReportLayout>
                }
                {(this.props.report.isLoading || this.state.isLoadingStatementsData) && this.renderLoading()}
            </div>
        )
    }

    validateIco = (errors) => {
        if (isNullOrUndefined(this.state.ico) || this.state.ico.length < 1) {
            errors.ico = this.props.t('Common.RequiredField');
        }
        else if (this.state.ico.length > 8) {
            errors.ico = `${this.props.t('Common.MaxLength')} 8`;
        }
        else if (!/^[0-9]*$/i.test(this.state.ico)) {
            errors.ico = this.props.t('Common.OnlyNumbers');
        }
        return errors;
    }

    findSubject = (type, country) => {
        let errors = {};
        errors = this.validateIco(errors);
        this.setState({ progress: 0, fileName: "", file: {}, filePath: "", errors: errors,
        financialStatementsSumary: null, IsRatingSkReport: false, calculationData: "", showModalEdit: false, showModalInsertFile: false, showModalView: false,
        statements: [], selectedStatements: [], finStatements: null, isLoadingStatementsData: false, subject: null, availableStatements: [] });
        if (this.state.ico !== "" && Object.keys(errors).length === 0) {
            apiGet(`api/report/search/subject`, { report: type, ico: this.state.ico, country }, (response) => this.fillSubject(response.data), (err) => writeErr(err));
        }
    }

    fillSubject(data) {
        if (isNullOrUndefined(data)) {
            toastMessage(this.props.t('IRating.Report.Search.SubjectNotFound'), "400");
        }
        else {
            this.setState({ ico: data.ico, name: data.name, address: data.address, city: data.city, postCode: data.postCode, subject: data });
            if (!isNullOrUndefined(data.naceCode) && this.props.domains.naceCodes.filter(function (e) { return e.code === data.naceCode; }).length > 0) {
                this.setState({ naceCode: [data.naceCode] });
            }
            if (!isNullOrUndefined(data.nutsCode) && this.props.domains.nutsCodes.filter(function (e) { return e.code === data.nutsCode; }).length > 0) {
                this.setState({ nutsCode: [data.nutsCode] });
            }
            if (!isNullOrUndefined(data.subjectType) && this.props.domains.subjectTypes.filter(function (e) { return e.code === data.subjectType; }).length > 0) {
                this.setState({ subjectType: [data.subjectType] });
            }
            if (!isNullOrUndefined(data.financialStatementsSumary)) {
                this.setState({ calculationData: data.financialStatementsSumary.dataCalculationFieldValue, financialStatementsSumary: data.financialStatementsSumary, IsRatingSkReport: data.isRatingSkReport });
                if (!isNullOrUndefined(data.financialStatementsSumary.financialStatementsAvailableData)) {
                    this.setState({ availableStatements: data.financialStatementsSumary.financialStatementsAvailableData });
                }
                if (!isNullOrUndefined(data.financialStatementsSumary.financialStatements) && data.financialStatementsSumary.financialStatements.length > 0 ) {
                    this.setState({ statements: data.financialStatementsSumary.financialStatements });
                }
                if(!data.isRatingSkReport) {
                    this.setState({ calculationData: this.props.t('IRating.Report.Search.NoDataToCalculation') });
                }
            }

        }
    }

    getFile = (event) => {
        event.persist();
        if (event.target.files.length > 0) {
            this.setState({ fileName: event.target.files[0].name, fileType: event.target.files[0].type });
            const reader = new FileReader();
            reader.onerror = this.errorHandler;
            reader.onprogress = (e) => this.progressHandler(e);
            reader.onload = (e) => this.processFile(e, event.target);
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    processFile(e) {
        let content = e.target.result.split("base64,")[1];
        let result = {};
        result.fileName = this.state.fileName;
        result.fileBytes = content;
        result.contentType = this.state.fileType;
        this.setState({ file: result });
    }

    resetProgress() {
        this.setState({ progress: 0 });
    }
    progressHandler(event) {
        if (event.lengthComputable) {
            this.setState({ progress: event.loaded / event.total * 100 })
        }
    }

    getFinStatementsIds() {
        if (isNullOrUndefined(this.state.selectedStatements))
            return "";
        return this.state.selectedStatements.map(x => x.value).join(';');
    }

    showHideModalEdit = () => {
        this.setState({ showModalEdit: (this.state.showModalEdit === false), isLoadingStatements: true });
    }
    showHideModalInsertFile = () => {
        this.setState({ showModalInsertFile: (this.state.showModalInsertFile === false) });
    }
    handleModalView = (country) => {
        this.setState({ isLoadingStatementsData: true });
        apiPost(`api/report/search/financial-statements`, { country: country }, { entityId: this.state.subject.entityId, finStatementsIds: this.state.statements.map(x => x.cas2003_Id) }, (response) => this.setState({ finStatementsData: response.data, showModalView: (this.state.showModalView === false), isLoadingStatementsData: false}), (err) => writeErr(err));
    }


    showHideModalView = () => {
        this.setState({ showModalView: (this.state.showModalView === false) });
    }

    renderSearch(type, country) {
        return (

            <div className="column is-6 form-layout">
                <div className="box">
                    {this.isSmeSk(type, country) && this.renderModalEdit(type, country)}
                    {this.isSmeSk(type, country) && this.renderModalInsertFile(type, country)}
                    {this.isSmeSk(type, country) && this.renderModalView()}
                    <div className="has-text-centered">
                        <h1 className="title is-4">{this.props.t('IRating.Report.Search.Title')}</h1>
                    </div>
                    <form onSubmit={(e) => this.handleSubmit(e, type, country)}>
                        <div className="container">
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.SubjectType')}>
                                <InputControl type="text" fieldName="type" value={this.props.t('IRating.Report.' + type + country)} disabled={true}
                                    placeholder={this.props.t('IRating.Report.Search.SubjectType')} />
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.Ico')} isSecondField={true}
                                secondField={<ButtonControl type="button" onClick={() => this.findSubject(type, country)} label={this.props.t('IRating.Report.Search.FindSubject')} />}
                            >
                                <InputControl type="text" fieldName="ico" error={this.state.errors.ico} value={this.state.ico} placeholder={this.props.t('IRating.Report.Search.Ico')} onChange={this.changeEvent} />
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.Name')}>
                                <InputControl type="text" fieldName="name" value={this.state.name} error={this.state.errors.name} placeholder={this.props.t('IRating.Report.Search.Name')} onChange={this.changeEvent} />
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.Address')}>
                                <InputControl type="text" fieldName="address" value={this.state.address} placeholder={this.props.t('IRating.Report.Search.Address')} onChange={this.changeEvent} />
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.PostCode')}>
                                <InputControl type="text" fieldName="postCode" value={this.state.postCode} placeholder={this.props.t('IRating.Report.Search.PostCode')} onChange={this.changeEvent} />
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.City')}>
                                <InputControl type="text" fieldName="city" value={this.state.city} placeholder={this.props.t('IRating.Report.Search.City')} onChange={this.changeEvent} />
                            </FieldHorizontal>

                            {hasPermission(null, "UserRatingMSP") &&
                                <FieldHorizontal label={this.props.t('IRating.Report.Search.Email')}>
                                    <InputControl type="email" fieldName="email" value={this.state.email} error={this.state.errors.email} placeholder={this.props.t('IRating.Report.Search.Email')}
                                        onChange={this.changeEvent} />
                                </FieldHorizontal>
                            }
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.DistrictCode')}>
                                <MultiSelect mode="single" enableSearch isLoading={this.props.domains.isLoading} value={this.state.nutsCode} options={this.props.domains.nutsCodes.map(x => { return { value: x.code, label: `${x.code} - ${x.name}` } })}
                                    onChange={nutsCode => this.setState({ nutsCode })} searchPlaceholder={this.props.t(`Common.Search`)} allSelectedLabel={this.props.t(`Search.Common.All`)} valuePlaceholder={this.props.t(`Common.Select`)} />
                            </FieldHorizontal>
                            {(type === REPORT_TYPES.sme || type === REPORT_TYPES.corp || type === REPORT_TYPES.entrepreneur) &&
                                <FieldHorizontal label={this.props.t(`IRating.Report.Search.Nace.${country}`)}>
                                    <MultiSelect mode="single" enableSearch resetable isLoading={this.props.domains.isLoading} value={this.state.naceCode} options={this.props.domains.naceCodes.map(x => { return { value: x.code, label: `${x.code} - ${x.name}` } })}
                                        onChange={naceCode => this.setState({ naceCode })} searchPlaceholder={this.props.t(`Common.Search`)} allSelectedLabel={this.props.t(`Search.Common.All`)} valuePlaceholder={this.props.t(`Common.Select`)} />
                                    {this.state.errors.naceCode && <p className="help is-danger">{this.state.errors.naceCode}</p>}
                                </FieldHorizontal>
                            }
                            {type === REPORT_TYPES.nonProfitOrganization.toLowerCase() &&
                                <FieldHorizontal label={this.props.t(`IRating.Report.Search.AreOfActivity`)}>
                                    <MultiSelect mode="single" enableSearch isLoading={this.props.domains.isLoading} value={this.state.subjectType} options={this.props.domains.subjectTypes.map(x => { return { value: x.code, label: x.name } })}
                                        onChange={subjectType => this.setState({ subjectType })} searchPlaceholder={this.props.t(`Common.Search`)} allSelectedLabel={this.props.t(`Search.Common.All`)} valuePlaceholder={this.props.t(`Common.Select`)} />
                                </FieldHorizontal>
                            }

                            {this.isSmeSk(type, country) &&

                                <FieldHorizontal label={this.props.t('IRating.Report.Search.CalculationData')}>
                                    <div className="field is-grouped">
                                        <div className="control is-expanded">
                                            <InputControl type="text" fieldName="calculationData" error={this.state.errors.calculationData} value={this.state.calculationData} onChange={this.changeEvent} />
                                        </div>
                                        <div className="control">
                                            <ButtonControl type="button" onClick={() => this.handleModalView(country)} label={this.props.t('IRating.Report.CalculationData.View')} disabled={isNullOrUndefined(this.state.statements) || this.state.statements.length === 0} />
                                        </div>
                                    </div>
                                </FieldHorizontal>
                            }

                            {!this.isSmeSk(type, country) && <FieldHorizontal label={this.props.t('IRating.Report.Search.ExcelFile')}>
                                <div className="control">
                                    <div className="file has-name is-fullwidth">
                                        <label className="file-label">
                                            <input className="file-input" type="file" value={this.state.filePath} name="filePath" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(event) => { this.getFile(event); this.changeEvent(event); }} />
                                            <span className={classnames("file-cta", { "is-danger": this.state.errors.file })} >
                                                <span className="file-icon">
                                                    <i className="fas fa-upload"></i>
                                                </span>
                                                <span className="file-label">
                                                    {this.props.t('IRating.Report.Search.FileUpload')}
                                                </span>
                                            </span>
                                            <span className={classnames("file-name", { "is-danger": this.state.errors.file })}>
                                                {this.state.fileName}
                                            </span>
                                        </label>

                                    </div>
                                    <div>
                                        <progress className="progress" value={this.state.progress} max="100"></progress>
                                    </div>
                                    {this.state.errors.file && <p className="help is-danger">{this.state.errors.file}</p>}
                                </div>
                            </FieldHorizontal>}
                            <ReportPermissionsFormComponent publishAboveRatingValue={this.state.publishAboveRating}
                                publishAboveRatingOnChange={publishAboveRating => this.setState({ publishAboveRating })}
                                partnersValue={this.state.partners} partnersOnChange={partners => this.setState({ partners })} />
                            {!hasPermission(null, "UserRatingMSP") &&

                                <FieldHorizontal label={this.props.t('IRating.Report.Search.MonitoredClient')}>
                                    <InputControl type="checkbox" fieldName="isMonitoredClient" value={this.state.isMonitoredClient} onChange={this.changeEventCheckbox} />
                                </FieldHorizontal>
                            }
                            <FieldHorizontal>
                                <div className="field is-grouped">
                                    <div className="control">
                                        <InputControl type="submit" value={this.props.t('IRating.Report.Search.OK')} disabled={this.isSmeSk(type, country) ? !this.state.IsRatingSkReport : false} />
                                    </div>
                                    {this.isSmeSk(type, country) &&
                                        <>
                                            <div className="control">
                                                <ButtonControl type="button" label={this.props.t('IRating.Report.Search.EditData')} isPrimary onClick={() => this.showHideModalEdit()} disabled={isNullOrUndefined(this.state.subject)}/>
                                            </div><div className="control">
                                                <ButtonControl type="button" label={this.props.t('IRating.Report.Search.InsertFile')} isPrimary onClick={() => this.showHideModalInsertFile()} disabled={isNullOrUndefined(this.state.subject)}/>
                                            </div>
                                        </>
                                    }
                                </div>
                            </FieldHorizontal>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    renderModalEdit(type, country) {
        return (
            <ModalWindow isActive={this.state.showModalEdit} title='IRating.Report.Search.Title'
                onClickCancel={() => this.showHideModalEdit()} cancelButtonName='Common.Back' hideHeaderClose >
                <div className="container">
                    <FieldHorizontal label={this.props.t('IRating.Report.Search.SubjectType')}>
                        <InputControl type="text" fieldName="type" value={this.props.t('IRating.Report.' + type + country)} disabled={true}
                            placeholder={this.props.t('IRating.Report.Search.SubjectType')} />
                    </FieldHorizontal>
                    <FieldHorizontal label={this.props.t('IRating.Report.Search.Ico')}>
                        <p>{this.state.ico}</p>
                    </FieldHorizontal>
                    <FieldHorizontal label={this.props.t('IRating.Report.Search.Name')}>
                        <p>{this.state.name}</p>
                    </FieldHorizontal>
                    <InputControl type="text" value={this.props.t('IRating.Report.Download.Scheme')} disabled={true} />
                    <FieldHorizontal label={this.props.t('IRating.Report.Search.DataFromCribis')}>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            menuPlacement='top'
                            closeMenuOnSelect={false}
                            placeholder={this.state.availableStatements.length > 0 ? this.props.t('IRating.Report.Search.Max3Items') : this.props.t('IRating.Report.Search.NoData')}
                            value={this.state.selectedStatements}
                            isMulti
                            //menuIsOpen
                            onChange={selectedStatements => this.setState({ selectedStatements })}
                            options={this.state.availableStatements.map(x => { return { value: x.cas2003_Id, label: `${x.endDateFormatted}${x.isIncorrect ? `- ${this.props.t('IRating.Report.Statements.IsIncorrect')}` : ''}` } })}
                            isOptionDisabled={() => this.state.selectedStatements.length >= 3}
                        />
                    </FieldHorizontal>
                    <a className="link-functional link-right" target="_blank" href={url.downloadTemplate(this.state.ico, this.getFinStatementsIds())}>{this.props.t('IRating.Common.Button.Download.Scheme')}</a>

                </div>
            </ModalWindow>
        );
    }

    renderModalInsertFile(type, country) {
        return (
            <ModalWindow isActive={this.state.showModalInsertFile} title='IRating.Report.Search.Title'
                onClickCancel={() => { this.setState({progress: 0, fileName: "", file: {}, filePath: ""}); this.showHideModalInsertFile();}} cancelButtonName='Common.Back'
                successButton={true} successButtonName='IRating.Report.Search.OK' onClickSuccess={(e) => { this.handleSubmit(e, type, country, true) }} hideHeaderClose>
                <div className="container">
                    <FieldHorizontal label={this.props.t('IRating.Report.Search.SubjectType')}>
                        <InputControl type="text" fieldName="type" value={this.props.t('IRating.Report.' + type + country)} disabled={true}
                            placeholder={this.props.t('IRating.Report.Search.SubjectType')} />
                    </FieldHorizontal>
                    <FieldHorizontal label={this.props.t('IRating.Report.Search.Ico')}>
                        <p>{this.state.ico}</p>
                    </FieldHorizontal>
                    <FieldHorizontal label={this.props.t('IRating.Report.Search.Name')}>
                        <p>{this.state.name}</p>
                    </FieldHorizontal>
                    <InputControl type="text" value={this.props.t('IRating.Report.InsertFile')} disabled={true} />
                    <FieldHorizontal label={this.props.t('IRating.Report.Search.ExcelFile')}>
                        <div className="control">
                            <div className="file has-name is-fullwidth">
                                <label className="file-label">
                                    <input className="file-input" type="file" value={this.state.filePath} name="filePath" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(event) => { this.getFile(event); this.changeEvent(event); }} />
                                    <span className={classnames("file-cta", { "is-danger": this.state.errors.file })} >
                                        <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                        </span>
                                        <span className="file-label">
                                            {this.props.t('IRating.Report.Search.FileUpload')}
                                        </span>
                                    </span>
                                    <span className={classnames("file-name", { "is-danger": this.state.errors.file })}>
                                        {this.state.fileName}
                                    </span>
                                </label>

                            </div>
                            <div>
                                <progress className="progress" value={this.state.progress} max="100"></progress>
                            </div>
                            {this.state.errors.file && <p className="help is-danger">{this.state.errors.file}</p>}
                        </div>
                    </FieldHorizontal>
                </div>
            </ModalWindow>
        );
    }

    renderModalView() {
        return (
            <ModalWindow hideFooter width isActive={this.state.showModalView} title='Report.FinancialStatements.SectionName'
                onClickCancel={() => this.showHideModalView()} cancelButtonName='IRating.Common.Button.Cancel'
                successButton={false}>
                <FinStatementsData data={this.state.finStatementsData} />
            </ModalWindow>
        );
    }
    isSmeSk(type, country){
        return (type === REPORT_TYPES.sme && country === COUNTRY_TYPES.SK);
    }

    renderMunicipalitySearch(type, country) {
        return (
            <div className="column is-6 form-layout">
                <div className="box">
                    <div className="has-text-centered">
                        <h1 className="title is-4">{this.props.t('IRating.Report.Search.Municipality.Title')}</h1>
                    </div>
                    <form onSubmit={(e) => this.handleMunicipalitySubmit(e, type, country)}>
                        <div className="container">
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.Name')}>
                                <InputControl type="text" fieldName="name" value={this.state.name} placeholder={this.props.t('IRating.Report.Search.Name')} onChange={this.changeEvent} />
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.Ico')}>
                                <InputControl type="text" fieldName="ico" value={this.state.ico} placeholder={this.props.t('IRating.Report.Search.Ico')} onChange={this.changeEvent} />
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.DistrictCode')}>
                                <InputControl type="text" fieldName="nutsCode" value={this.state.nutsCode[0]} placeholder={this.props.t('IRating.Report.Search.DistrictCode')} onChange={e => this.setState({ nutsCode: [e.target.value] })} />
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.MonitoredClient')}>
                                <InputControl type="checkbox" fieldName="isMonitoredClient" value={this.state.isMonitoredClient} onChange={this.changeEventCheckbox} />
                            </FieldHorizontal>
                            <FieldHorizontal>
                                <InputControl type="submit" value={this.props.t('IRating.Report.Search.OK')} />
                            </FieldHorizontal>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default withTranslation("translation")(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(ReportSearchFormComponent));