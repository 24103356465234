import { LOCATION_CHANGE } from 'react-router-redux';
import { toastMessage } from '../components/common/message/message'
import { apiGet, apiPost, apiPut, parseQuery, hasPermission, redirectTo } from './apiWrapper';
import { updateObject, updateObjectProperty, isNullOrUndefined } from './storeFunctions';
import { writeErr, writeDebug, writeWarn } from '../components/common/logger';
import { push } from 'react-router-redux';
import i18next from './../i18n'

const userLogin = "USER_LOGIN";
const userLoginReset = "USER_LOGIN_RESET";
const userLogout = "USER_LOGOUT";
const userLoginRequest = "USER_LOGIN_REQUEST";
const userLoginFailed = "USER_LOGIN_FAILED";
const reportMunicipalityRequest = "REPORT_MUNICIPALITY_REQUEST";
const reportMunicipalityFailed = "REPORT_MUNICIPALITY_FAILED";
const reportMunicipalitySuccess = "REPORT_MUNICIPALITY_SUCCESS";
const reportHistoryRequest = "REPORT_HISTORY_REQUEST";
const reportHistoryFailed = "REPORT_HISTORY_FAILED";
const reportHistorySuccess = "REPORT_HISTORY_SUCCESS";
const reportRequest = "REPORT_REQUEST";
const reportFailed = "REPORT_FAILED";
const reportSuccess = "REPORT_SUCCESS";
const reportLoading = "REPORT_LOADING";
const reportStopLoading = "REPORT_STOP_LOADING";
const userExpired = "LOGIN_EXPIRED";
const reportDomainsSuccess = "REPORT_DOMAIN_SUCCESS";
const reportDomainsRequest = "REPORT_DOMAIN_REQUEST";
const reportDomainsError = "REPORT_DOMAIN_ERROR"
const reportDomainsRatingRangeSuccess = "REPORT_DOMAIN_RANGE_SUCCESS";
const reportDomainsRatingRangeRequest = "REPORT_DOMAIN_RANGE_REQUEST";
const reportDomainsRatingRangeError = "REPORT_DOMAIN_RANGE_ERROR"
const reportApprovalsSuccess = "REPORT_APPROVAL_SUCCESS";
const reportApprovalsRequest = "REPORT_APPROVAL_REQUEST";
const reportApprovalsFailed = "REPORT_APPROVAL_ERROR"
const changingLanguage = "CHANGING_LANGUAGE";
const languageChanged = "LANGUAGE_CHANGED";
const updatePermissionsRequest = "UPDATE_PERMISSIONS_REQUEST";
const updatePermissionsSuccess = "UPDATE_PERMISSIONS_SUCCESS";
const updatePermissionsFailed = "UPDATE_PERMISSIONS_FAILED";
const updateUserRequest = "UPDATE_USER_REQUEST";
const updateUserSuccess = "UPDATE_USER_SUCCESS";
const updateUserFailed = "UPDATE_USER_FAILED";
const settingsReset = "SETTINGS_RESET";
const reportOfferSearchRequest = "REPORT_OFFERSEARCH_REQUEST";
const reportOfferSearchFailed = "REPORT_OFFERSEARCH_FAILED";
const reportOfferSearchSuccess = "REPORT_OFFERSEARCH_SUCCESS";
const userChangePwd = "USER_CHANGE_PWD";

const reportOfferRequest = "REPORT_OFFER_REQUEST";
const reportOfferFailed = "REPORT_OFFER_FAILED";
const reportOfferSuccess = "REPORT_OFFER_SUCCESS";

const reportDomainsHistorySuccess = "REPORT_DOMAIN_HISTORY_SUCCESS";
const reportDomainsHistoryRequest = "REPORT_DOMAIN_HISTORY_REQUEST";
const reportDomainsHistoryError = "REPORT_DOMAIN_HISTORY_ERROR"

export const errorType = 'MAKE_USER_UNHAPPY';

const initAuth = { isAuthorized: false, error: undefined, loading: false, isGeneralUser: false };
const initSearch = { isSearching: false, searchResult: null, error: null };
const initOfferSearch = { isSearching: false, offerResult: null, error: null };
const initOffer = { isLoading: false, offerResult: null, error: null };
const initSettings = { isUpdating: false, update: null, error: null };
const initSearchMunicip = { isSearching: false, searchResult: null, error: null };
const initReport = { isLoading: false, reportResult: null, error: null, isLoadingPublishUpdate: false };
const initRatingRange = { isLoading: false, rating: [], ratingRange: [], ratingFrom: null, ratingTo: null, error: null };
const initDomains = { isLoading: false, report: null, country: null, naceCodes: [], nutsCodes: [], subjectTypes: [], partners: [], error: null };
const initHistoryDomains = { isLoading: false, customers: [], error: null };
const initialState = restoreFromStorage({
    isLoading: false, isLoaded: false, domains: { ...initDomains }, authentication: { ...initAuth },
    search: { ...initSearch }, searchMunicip: { ...initSearchMunicip }, report: { ...initReport }, ratingRange: { ...initRatingRange }, settings: { ...initSettings },
    offer: { ...initOffer }, offerSearch: { ...initOfferSearch }, domainsHistory: {...initHistoryDomains},  languages: [
        { name: "Slovensky", code: "sk-SK" },
        { name: "Česky", code: "cs-CZ" },
        { name: "Deutsch", code: "de-DE" },
        { name: "English", code: "en-US" }
    ]
});

//action creator for redux
export const appContextActions = {
    fetchTranslations: (language, setAsActive) => async (dispatch, getState) => {
        const locData = localStorage.getItem('locales_data');
        const locLang = localStorage.getItem('locales_lang');
        const locVersion = localStorage.getItem('locales_version');

        dispatch({ type: changingLanguage });
        if (locLang === language) {
            if (locData !== null && locData !== undefined) {
                i18next.addResourceBundle(language, "translation", JSON.parse(locData) );
                i18next.changeLanguage(language);
                apiGet(`/lang/${language}`, { version: true }, res => {
                    if (res.version !== locVersion) {
                        writeDebug(`Local translations with version:[${locVersion}] is out-of-date loading fresh data`);
                        apiGet(`/lang/${language}`, { version: true, data: true }, res => {
                            localStorage.setItem('locales_data', JSON.stringify(res.data));
                            localStorage.setItem('locales_version', res.version);
                            i18next.addResourceBundle(language, "translation", res.data);
                            if (setAsActive === true) {
                                i18next.changeLanguage(language);
                            }
                            dispatch({ type: languageChanged });
                        });
                    } else {
                        writeDebug(`Using local translations with version:[${locVersion}]`)
                        dispatch({ type: languageChanged });
                    }
                });
            } else {
                writeDebug(`Local translations not found loading`);
                apiGet(`/lang/${language}`, { version: true, data: true }, res => {
                    localStorage.setItem('locales_data', JSON.stringify(res.data));
                    localStorage.setItem('locales_version', res.version);
                    i18next.addResourceBundle(language, "translation", res.data);
                    if (setAsActive === true) {
                        i18next.changeLanguage(language);
                    }
                    dispatch({ type: languageChanged });
                });
            }
        } else {
            writeDebug(`Local translations language does not match loading fresh data`);
            apiGet(`/lang/${language}`, { version: true, data: true }, res => {
                localStorage.setItem('locales_data', JSON.stringify(res.data));
                localStorage.setItem('locales_lang', language);
                localStorage.setItem('locales_version', res.version);
                i18next.addResourceBundle(language, 'translation', res.data);
                if (setAsActive === true) {
                    i18next.changeLanguage(language);
                }
                dispatch({ type: languageChanged });
            });
        }
    },
    login: (username, pswd, url) => async (dispatch, getState) => {
        if (getState().appContext.authentication.loading) {
            return;
        }
        dispatch({ type: userLoginRequest });
        apiPost(`api/account/login`, undefined, { username: username, password: pswd, grant_type: 'password' }, res => {
            if (!res.data.success) {
                dispatch({ type: userLoginFailed, data: res.data });
            } else {
                dispatch({ type: userLogin, data: res.data, isGeneralUser: false });
                redirectToBack(dispatch, url);
            }
        }, err => {
            dispatch({ type: userLoginFailed, data: { isAuthorized: false, error: err, token: null, expire: null, loading: false } });
        });
    },
    loginSso: (token) => async (dispatch, getState) => {
        if (getState().appContext.authentication.loading) {
            return;
        }
        dispatch({ type: userLoginRequest });
        apiPost(`api/account/login-sso`, { token: token }, undefined, res => {
            if (!res.data.success) {
                dispatch({ type: userLoginFailed, data: res.data });
            } else {
                dispatch({ type: userLogin, data: res.data, isGeneralUser: false });
                redirectToBack(dispatch, "/");
            }
        }, err => {
            dispatch({ type: userLoginFailed, data: { isAuthorized: false, error: err, token: null, expire: null, loading: false } });
        });
    },
    loginGeneralUser: (username, pswd, url) => async (dispatch, getState) => {
        if (getState().appContext.authentication.loading) {
            return;
        }
        dispatch({ type: userLoginRequest });
        apiPost(`api/account/login-user`, undefined, { username: username, password: pswd, grant_type: 'password' }, res => {
            if (!res.data.success) {
                dispatch({ type: userLoginFailed, data: res.data });
            } else {
                dispatch({ type: userLogin, data: res.data, isGeneralUser: true });
                redirectToBack(dispatch, url);
            }
        }, err => {
            dispatch({ type: userLoginFailed, data: { isAuthorized: false, error: err, token: null, expire: null, loading: false } });
        });
    },
    logout: (url) => async (dispatch, getState) => {
        localStorage.removeItem('auth');
        dispatch({ type: userLogout })
        redirectToBack(dispatch, url);
    },
    redirectBack: (url) => async (dispatch, getState) => redirectToBack(dispatch, url),
    resetLoginAttempt: () => async (dispatch, getState) => dispatch({ type: userLoginReset }),

    getMunicipality: (name, ico, nutsCode, country) => async (dispatch, getState) => {
        dispatch({ type: reportMunicipalityRequest });
        apiPost(`api/report/municipality/${country}`, undefined, { name, ico, nutsCode }, res => {
            dispatch({ type: reportMunicipalitySuccess, data: res });
        }, err => {
            dispatch({ type: reportMunicipalityFailed, error: err });
        }
        );
    },

    getHistory: (name, ico, ratingType, ratingFrom, ratingTo, filterDateFrom, filterDateTo, customerId = null) => async (dispatch, getState) => {
        dispatch({ type: reportHistoryRequest });
        apiPost(`api/history/search`, undefined, { name, ico: ico, ratingType: ratingType, ratingFrom: ratingFrom, ratingTo: ratingTo, filterDateFrom: filterDateFrom, filterDateTo: filterDateTo, customerId: customerId }, res => {
            dispatch({ type: reportHistorySuccess, data: res });
        }, err => {
            dispatch({ type: reportHistoryFailed, error: err });
        }
        );
    },
    getOffers: (name) => async (dispatch, getState) => {
        dispatch({ type: reportOfferSearchRequest });
        apiGet(`api/offer`, { name }, res => {
            dispatch({ type: reportOfferSearchSuccess, data: res });
        }, err => {
            dispatch({ type: reportOfferSearchFailed, error: err });
        }
        );
    },
    getOffer: (name) => async (dispatch, getState) => {
        dispatch({ type: reportOfferRequest });
        apiGet(`api/offer/${name}`, undefined, res => {
            dispatch({ type: reportOfferSuccess, data: res });
        }, err => {
            dispatch({ type: reportOfferFailed, error: err });
        }
        );
    },
    getNoApproved: () => async (dispatch, getState) => {
        dispatch({ type: reportHistoryRequest });
        apiGet(`api/history/approvals`, undefined, res => {
            dispatch({ type: reportHistorySuccess, data: res });
        }, err => {
            dispatch({ type: reportHistoryFailed, error: err });
        }
        );
    },
    approvalsRating: (guid, approve, comment) => async (dispatch, getState) => {
        dispatch({ type: reportApprovalsRequest });
        apiPost(`api/report/approvals/${guid}`, undefined, { guid, approve, comment }, res => {
            if (isNullOrUndefined(res.error)) {
                dispatch({ type: reportApprovalsSuccess, data: res, reportId: guid });
                dispatch(push(`/approvals`));
                if (approve) {
                    toastMessage("Error_Message_RatingApproved", "200");
                }
                else {
                    toastMessage("Error_Message_RatingDisApproved", "200");
                }
            }
            else {
                dispatch({ type: reportApprovalsSuccess, data: res, reportId: guid });
                toastMessage(res.error.message, res.error.code);
            }
        }, err => {
            dispatch({ type: reportApprovalsFailed, error: err });
            toastMessage("Error_Message_UpdatePublishFailed", "400");
        }
        );
    },

    getHistoryReport: (guid) => async (dispatch, getState) => {
        if (getState().appContext.report.id === guid) {
            return;
        }

        dispatch({ type: reportRequest });
        apiGet(`api/history/report/${guid}`, undefined, res => {
            dispatch({ type: reportSuccess, data: res, reportId: guid });
        }, err => {
            dispatch({ type: reportFailed, error: err });
        }
        );
    },

    updatePublishPermissions: (guid, partners, publishAboveRating) => async (dispatch, getState) => {
        dispatch({ type: updatePermissionsRequest });
        apiPost(`api/report/permissions`, undefined, { guid, partners, publishAboveRating }, res => {
            toastMessage("Error_Message_UpdatePublishSuccessfull", "200");
            dispatch({ type: updatePermissionsSuccess, data: res, reportId: guid, publishAboveRating: publishAboveRating });
        }, err => {
            toastMessage("Error_Message_UpdatePublishFailed", "400");
            dispatch({ type: updatePermissionsFailed, error: err });
        }
        );
    },
    updateUser: (userId, email, name, surname, language) => async (dispatch, getState) => {
        dispatch({ type: updateUserRequest });
        apiPut(`api/user/user/${userId}`, undefined,
            { name, surname, email, language }, (res) => {

                if (isNullOrUndefined(res.error)) {
                    dispatch({ type: updateUserSuccess, data: res, email, name, surname });
                    toastMessage("Error_Message_SaveUserSuccessfull", "200");
                }
                else {
                    dispatch({ type: updateUserSuccess, data: res, email, name, surname });
                    toastMessage("Error_Message_SaveUserFailed", "400");
                }
            }, err => {
                writeErr(err);
                dispatch({ type: updateUserFailed, error: err });
                toastMessage(err.data.error.message, err.data.error.code);
            }
        );
    },

    createReport: (report, data, country, monitored) => async (dispatch, getState) => {
        dispatch({ type: reportLoading });
        apiPost(`api/report/rating/${report}/${country}/${monitored}`, undefined,
            data, res => {
                if (isNullOrUndefined(res.error)) {
                    if (hasPermission(null, "UserRatingMSP")) {
                        dispatch({ type: reportStopLoading });
                        toastMessage("Error_Message_ReportCreated", "200");
                        dispatch(push(`/report`));
                    }
                    else {
                        dispatch(push(`/report/result?id=${res.data}`));
                    }
                }
                else {
                    dispatch({ type: reportStopLoading });
                    toastMessage(res.error.message, res.error.code);
                }
            }, err => {
                writeErr(err);
                toastMessage(err.data.error.message, err.data.error.code, true, false);
                dispatch({ type: reportStopLoading });
            }
        );
    },

    loadDropdownItems: (report, country) => async (dispatch, getState) => {
        const doms = getState().appContext.domains;
        if (getState().appContext.domains.isLoading || country === null || country === undefined) {
            return;
        }
        if (doms.report === report && doms.country === country && doms.naceCodes.length > 0 && doms.nutsCodes.length > 0) {
            return;
        }
        if (doms.report !== report && doms.country === country && doms.naceCodes.length > 0 && doms.nutsCodes.length > 0) {
            dispatch({ type: reportDomainsSuccess, data: doms, country, report });
            return;
        }
        dispatch({ type: reportDomainsRequest });
        apiGet(`api/report/search/dropdown`, { country },
            res => dispatch({ type: reportDomainsSuccess, data: res.data, country, report }),
            err => dispatch({ type: reportDomainsError, error: err })
        );
    },
    changePwd: () => async (dispatch, getState) => {
        dispatch({ type: userChangePwd })
        redirectTo('/login');
    },

    loadRatingRange: (rating) => async (dispatch, getState) => {
        const doms = getState().appContext.ratingRange;
        if (getState().appContext.ratingRange.isLoading || rating.length === 0 || rating === undefined) {
            return;
        }
        if (doms.rating[0] === rating[0] && doms.ratingRange.length > 0) {
            return;
        }
        dispatch({ type: reportDomainsRatingRangeRequest });
        apiGet(`api/history/search/ratings`, { rating: rating[0] },
            res => dispatch({ type: reportDomainsRatingRangeSuccess, data: res.data, rating }),
            err => dispatch({ type: reportDomainsRatingRangeError, error: err })
        );
    },
    resetSettings: () => async (dispatch, getState) => {
        dispatch({ type: settingsReset });
    },
    loadDomainHistory: () => async (dispatch, getState) => {
        const doms = getState().appContext.domainsHistory;
        if (getState().appContext.domainsHistory.isLoading) {
            return;
        }
        if (doms.customers.length > 0) {
            return;
        }
        
        dispatch({ type: reportDomainsHistoryRequest });
        apiGet(`api/history/search/dropdown`, undefined,
            res => dispatch({ type: reportDomainsHistorySuccess, data: res.data }),
            err => dispatch({ type: reportDomainsHistoryError, error: err })
        );
    },

};

// store reducer
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case changingLanguage:
            return updateObject(state, { langLoading: true });
        case languageChanged:
            return updateObject(state, { langLoading: false });
        case LOCATION_CHANGE:
            return { ...state, location: action.payload.pathname };
        case userLoginRequest:
            return updateObject(state, { authentication: updateObject(state.authentication, { loading: true, error: undefined }) });
        case userLogin:
            let dataul = { isAuthorized: action.data.success, ...action.data, loading: false, isGeneralUser: action.isGeneralUser };
            localStorage.setItem('auth', JSON.stringify(dataul));
            return updateObject({ ...state, isLoaded: false, isLoading: false }, { authentication: updateObject(state.authentication, dataul) });

        case reportDomainsSuccess:
            return updateObject({ ...state }, { domains: updateObject(state.domains, { nutsCodes: action.data.nutsCodes, naceCodes: action.data.naceCodes, subjectTypes: action.data.subjectTypes, partners: action.data.partners, isLoading: false, error: null, report: action.report, country: action.country }) });
        case reportDomainsRequest:
            return updateObject({ ...state }, { domains: updateObject(state.domains, { isLoading: true }) })
        case reportDomainsError:
            return updateObject({ ...state }, { domains: updateObject(state.domains, { nutsCodes: [], naceCodes: [], subjectTypes: [], partners: [], isLoading: false, error: action.error, report: null, country: null }) });
        case reportDomainsRatingRangeSuccess:
            return updateObject({ ...state }, { ratingRange: updateObject(state.ratingRange, { isLoading: false, rating: action.rating, ratingRange: action.data, ratingFrom: action.data[0], ratingTo: action.data[action.data.length - 1], error: null }) });
        case reportDomainsRatingRangeRequest:
            return updateObject({ ...state }, { ratingRange: updateObject(state.ratingRange, { isLoading: true }) })
        case reportDomainsRatingRangeError:
            return updateObject({ ...state }, { ratingRange: updateObject(state.ratingRange, { isLoading: false, rating: [], ratingRange: [], ratingFrom: null, ratingTo: null, error: action.error }) });

        case userLoginFailed:
            let dataulf = { isAuthorized: action.data.success, ...action.data, loading: false };
            return updateObject({ ...state, isLoading: false }, { authentication: updateObject(state.authentication, dataulf) })
        case userLogout:
            return {
                ...state, isChangePwd: false, navItems: [], authentication: { ...initAuth }, domains: { ...initDomains }, search: { ...initSearch }, searchMunicip: { ...initSearchMunicip },
                report: { ...initReport }, ratingRange: { ...initRatingRange }, settings: { ...initSettings }
            };
        case userLoginReset:
            return updateObject(state, { authentication: updateObject(state.authentication, { error: undefined }) });

        case reportMunicipalityRequest:
            return updateObject(state, { searchMunicip: updateObject(state.searchMunicip, { isSearching: true, searchResult: null, error: null }) });
        case reportMunicipalitySuccess:
            return updateObject(state, { searchMunicip: updateObject(state.searchMunicip, { isSearching: false, searchResult: action.data, error: null }) });
        case reportMunicipalityFailed:
            return updateObject(state, { searchMunicip: updateObject(state.searchMunicip, { isSearching: false, searchResult: null, error: action.error }) });

        case reportApprovalsRequest:
            return updateObject(state, { report: updateObject(state.report, { isLoading: true, error: null }) });
        case reportApprovalsSuccess:
            return updateObject(state, { report: updateObject(state.report, { isLoading: false, error: null }) });
        case reportApprovalsFailed:
            return updateObject(state, { report: updateObject(state.report, { isLoading: false, error: action.error }) });
        case reportHistoryRequest:
            return updateObject(state, { search: updateObject(state.search, { isSearching: true, searchResult: null, error: null }), report: { ...initReport } });
        case reportHistorySuccess:
            return updateObject(state, { search: updateObject(state.search, { isSearching: false, searchResult: action.data, error: null }) });
        case reportHistoryFailed:
            return updateObject(state, { search: updateObject(state.search, { isSearching: false, searchResult: null, error: action.error }) });
        case reportRequest:
            return updateObject(state, { report: updateObject(state.report, { isLoading: true, reportResult: null, error: null, isLoadingPublishUpdate: false }) });
        case reportSuccess:
            return updateObject(state, { report: updateObject(state.report, { isLoading: false, reportResult: action.data, id: action.reportId, error: null, isLoadingPublishUpdate: false }) });
        case reportFailed:
            return updateObject(state, { report: updateObject(state.report, { isLoading: false, reportResult: null, error: action.error, isLoadingPublishUpdate: false }) });
        case reportLoading:
            return updateObject(state, { report: updateObject(state.report, { isLoading: true }) });
        case reportStopLoading:
            return updateObject(state, { report: updateObject(state.report, { isLoading: false }) });
        case updatePermissionsRequest:
            return updateObject(state, { report: updateObject(state.report, { isLoadingPublishUpdate: true }) });
        case updatePermissionsSuccess:
            return updateObject(state, {
                report: updateObject(state.report, {
                    isLoadingPublishUpdate: false,
                    reportResult: updateObject(state.report.reportResult, {
                        data: updateObject(state.report.reportResult.data, {
                            partners: action.data.data, publishAboveRating: action.publishAboveRating
                        })
                    })
                })
            });
        case updatePermissionsFailed:
            return updateObject(state, { report: updateObject(state.report, { isLoadingPublishUpdate: false, error: action.error }) });
        case updateUserRequest:
            return updateObject(state, { settings: updateObject(state.settings, { isUpdating: true, update: null, error: null }) });
        case updateUserSuccess:
            return updateObject(state, {
                settings: updateObject(state.settings, { isUpdating: false, update: action.data, error: null }),
                authentication: action.data == 0 ? state.authentication : updateObject(state.authentication, {
                    profile: updateObject(state.authentication.profile, {
                        name: action.name, email: action.email, surname: action.surname
                    })
                })
            });
        case updateUserFailed:
            return updateObject(state, { settings: updateObject(state.settings, { isUpdating: false, update: null, error: action.error }) });
        case settingsReset:
            return updateObject(state, { settings: { ...initSettings } });
        case userExpired:
            localStorage.removeItem('auth');
            return updateObject(state, { authentication: { ...initAuth } });
        case reportOfferRequest:
            return updateObject(state, { offer: updateObject(state.offer, { isLoading: true, offerResult: null, error: null }) });
        case reportOfferSuccess:
            return updateObject(state, { offer: updateObject(state.offer, { isLoading: false, offerResult: action.data, error: null }) });
        case reportOfferFailed:
            return updateObject(state, { offer: updateObject(state.offer, { isLoading: false, offerResult: null, error: action.error }) });
        case reportOfferSearchRequest:
            return updateObject(state, { offerSearch: updateObject(state.offerSearch, { isSearching: true, offerResult: null, error: null }) });
        case reportOfferSearchSuccess:
            return updateObject(state, { offerSearch: updateObject(state.offerSearch, { isSearching: false, offerResult: action.data, error: null }) });
        case reportOfferSearchFailed:
            return updateObject(state, { offerSearch: updateObject(state.offerSearch, { isSearching: false, offerResult: null, error: action.error }) });
        case userChangePwd:
            return updateObject(state, {isChangePwd: true });
        case reportDomainsHistorySuccess:
            return updateObject({ ...state }, { domainsHistory: updateObject(state.domainsHistory, { customers: action.data, isLoading: false, error: null }) });
        case reportDomainsHistoryRequest:
            return updateObject({ ...state }, { domainsHistory: updateObject(state.domainsHistory, { isLoading: true }) })
        case reportDomainsHistoryError:
            return updateObject({ ...state }, { domainsHistory: updateObject(state.domainsHistory, { customers: [], isLoading: false, error: action.error }) });
        default:
            return state;
    }
}

function redirectToBack(dispatch, url) {
    const q = parseQuery(url);
    const redir = q.returnUrl !== null && q.returnUrl !== undefined ? decodeURIComponent(q.returnUrl) : '/';
    writeDebug("Redirecting to " + redir);
    dispatch(push(redir));
}

function restoreFromStorage(initial) {
    let auth = localStorage.getItem('auth');

    if (auth !== null && auth !== undefined) {
        auth = JSON.parse(auth);
        if (auth === null || auth === undefined) {
            return initial;
        }

        if (Date.parse(auth.expire) < Date.now()) {
            localStorage.removeItem('auth');
            return initial;
        }
    }
    return updateObject(initial, { authentication: updateObject(initial.authentication, { ...auth, loading: false }) });
}