import React from "react";
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getElementBoundingRect, getClientWindowHeight } from '../../store/almightyElementWrapper';

import "./horizontalScrollControl.scss";

class HorizontalScrollControl extends React.Component {
    state = {
        isBeginPos: true, isEndPos: false,
        movePos: 0, hasControls: false, controlsTop: null
    };

    render() {
        return (
            <div className="horizontal-scroll-container" style={this.props.withMarginBottom ? { marginBottom: '1.5em' } : {}}>
                {this.state.hasControls && <div className="horizontal-scroll-controls" style={{ width: this.repSelRef.offsetWidth, top: this.state.controlsTop }}>
                    <span className={classnames('arrow left', { 'active': !this.state.isBeginPos })} onClick={(e) => this.scrollX(e, 'left')} style={{ marginLeft: this.props.leftArrowMargin }}>
                        <FontAwesomeIcon icon='angle-left' />
                    </span>
                    <span className={classnames('arrow right', { 'active': !this.state.isEndPos })} onClick={(e) => this.scrollX(e, 'right')}>
                        <FontAwesomeIcon icon='angle-right' />
                    </span>
                </div>}
                <div className="menu-wrapper" ref={el => this.linkRef(el)} style={this.state.hasControls ? {} : { width: '100%' }}>
                    {this.props.children}
                </div>
            </div >
        );
    }

    componentDidMount() {
        const that = this; // because of setInterval stupidity
        window.addEventListener("resize", this.handleWindowResize);
        window.addEventListener("scroll", this.handleScrollY);
        this.updaterInterval = window.setInterval(function () { that.solveControls(); }, 500);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
        window.removeEventListener("scroll", this.handleScrollY);
        window.clearInterval(this.updaterInterval);
    }

    handleWindowResize = (e) => {
        this.solveControls();
        this.solveControlsTop();
    }

    handleScrollY = (e) => {
        this.solveControlsTop();
    }

    scrollX = (event, dir) => {
        event.preventDefault();
        const min = 0;
        const max = this.repSelRef.scrollWidth - this.repSelRef.offsetWidth;
        const shift = this.repSelRef.offsetWidth / 2;
        const movePos = (dir === 'left') ? Math.max(min, this.state.movePos - shift) : Math.min(max, this.state.movePos + shift);

        if (this.repSelRef.scrollTo) {
            this.repSelRef.scrollTo({ left: movePos, behavior: 'smooth' });
        } else {
            this.repSelRef.scrollLeft = movePos;
        }
        this.setState({ movePos, isBeginPos: movePos === min, isEndPos: movePos === max });
    }

    linkRef = (el) => {
        if (el === null || el === undefined || this.repSelRef === el) {
            return;
        }
        this.repSelRef = el;
        this.solveControls();
        this.solveControlsTop();
    }

    solveControls = () => {
        if (this.repSelRef === null || this.repSelRef === undefined) {
            return;
        }

        const hasControls = this.repSelRef.scrollWidth > this.repSelRef.offsetWidth;
        if (this.state.hasControls != hasControls) {
            this.setState({ hasControls })
        }
    }

    solveControlsTop = () => {
        if (this.repSelRef === null || this.repSelRef === undefined) {
            return;
        }

        let controlsTop = this.state.controlsTop;

        const rect = this.repSelRef.getBoundingClientRect();
        const controlsHeight = getElementBoundingRect('horizontal-scroll-controls', _ => _.height);
        const controlsCenter = controlsHeight / 2;
        const windowHeight = getClientWindowHeight();

        if (rect.top <= 0 && rect.bottom >= windowHeight) {
            controlsTop = `calc(50% - ${controlsCenter}px)`
        } else if (rect.top > 0 && rect.bottom < windowHeight) {
            controlsTop = Math.max(rect.top, rect.top + rect.height / 2 - controlsCenter);
        } else if (rect.top > 0) {
            controlsTop = Math.max(rect.top, rect.top + (windowHeight - rect.top) / 2 - controlsCenter);
        } else if (rect.bottom < windowHeight) {
            controlsTop = Math.min(rect.bottom - controlsHeight, (windowHeight - (windowHeight - rect.bottom)) / 2 - controlsCenter);
        }

        this.setState({ controlsTop });
    }
}

export default withRouter(withTranslation("translation")(HorizontalScrollControl));