import React from 'react';
import { withRouter } from "react-router";
import LeftNavbar from '../report/leftNavBar/leftNavbar';

class ReportLayout extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="columns work-container">
                    <div className="column left-navbar-container">
                        <div className="box">
                            <LeftNavbar type={this.props.type} country={this.props.country}/>
                        </div>
                    </div>
                    <div className="column right-body-container is-three-quarters">
                        {this.props.children}
                    </div>
                    <div className="column">
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ReportLayout);