import { parseDate } from 'react-day-picker/moment';
import { isNullOrUndefined } from '../../store/storeFunctions';

function customParseDate(str, format, locale) {
    let ret = parseDate(str, format, locale);
    if (!isNullOrUndefined(ret)) return ret;
    //try
    ret = parseDate(str, 'D.M.YYYY', locale);
    if (!isNullOrUndefined(ret)) return ret;

    ret = parseDate(str, 'M/D/YYYY', locale);
    if (!isNullOrUndefined(ret)) return ret;

    ret = parseDate(str, 'YYYY-M-D', locale);
    if (!isNullOrUndefined(ret)) return ret;
    return ret;
}

export { customParseDate };