import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../../store/appContextStore'
import { withRouter } from "react-router";
import { withTranslation, Trans  } from 'react-i18next';
import { isNullOrUndefined } from '../../store/storeFunctions';
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="content has-text-centered">
                    { this.getFooter() }&nbsp;|&nbsp;
                    <Link className="bb-footer-links" to="/personal-data/gdpr"><Trans i18nKey="Footer.PersonalData" /></Link>
                </div>
            </footer>
        );
    }

    getFooter(){
        if(!isNullOrUndefined(this.props) && !isNullOrUndefined(this.props.authentication) && !isNullOrUndefined(this.props.authentication.profile) && !isNullOrUndefined(this.props.authentication.profile.agentLanguage)){
            return this.props.authentication.profile.agentLanguage === 'cs-CZ' ? <p><i>{this.props.t('IRating.GenerateReport.Footer.CZ')}</i></p> : <p><i>{this.props.t('IRating.GenerateReport.Footer')}</i></p>
        }
        else{
            return window.location.hostname.includes("cz") ? <p><i>{this.props.t('IRating.GenerateReport.Footer.CZ')}</i></p> : <p><i>{this.props.t('IRating.GenerateReport.Footer')}</i></p>
        }

    }
}

Footer.propTypes = {
}

export default withTranslation("translation")(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(withRouter(Footer)));