import React from "react";
import classnames from 'classnames';
import { Trans } from 'react-i18next';

/**
 * @param {bool} offsetLeft - true adds 2.5 padding on left via old-style-left-offset css class
 * @param {bool} offsetLeftHalf - true adds 1.25 padding on left via old-style-left-offset-half css class
 * @param {function} head - function for thead rending
 * @param {function} historyHead - function for history thead rending
 * @param {number} tableColumns - used as colSpan for paging row
 * @param {Array} colWidths - array of column width items used to create <colgroup> element - if this is populated tableColumns is not needed
 * @param {bool} colWidthsOnMobile - use column widths on mobile
 * @param {Array} actualData - actual data array
 * @param {Array} historyData - history data array
 * @param {function} actualRow - function for rendering actual data row
 * @param {function} historyRow - function for rendering history data row
 * @param {string} showAllLink - redirect url for all content
 * @param {function} resolveExpanded - function to determine if subsections should be expanded (passed from reportActions)
 * @param {bool} stripped - apply old-style-stripped class to <tbody>
 * @param {bool} fixed - use fixed table layout
 * @param {object} classNames - dictionary of classes with conditions
 */
class OldStyleTableWithHistory extends React.Component {
    static defaultProps = {
        colWidthsOnMobile: true,
        fixed: true,
        classNames: {}
    };

    state = {
        historyExpanded: false,
        isHistoryExpandedTouched: false,
        page: {
            actual: 1,
            historical: 1
        }
    };


    render() {
        const hasActual = (this.props.actualData || []).length > 0;
        const hasHistory = (this.props.historyData || []).length > 0;

        let cls = { 'old-style-left-offset': this.props.offsetLeft === true, 'old-style-left-offset-half': this.props.offsetLeftHalf === true, 'fixed': this.props.fixed !== false };
        if (typeof this.props.classNames === 'string' || this.props.classNames instanceof String) {
            cls[this.props.classNames] = true;
        } else {
            cls = { ...cls, ...this.props.classNames }
        }

        const tableMargin = this.props.noMargin === true ? undefined : { marginBottom: '1.5em' };

        return (
            <table className={classnames("table old-style-table-report", cls)} style={tableMargin}>
                {this.props.colWidths && <colgroup className={classnames({ "hide-on-mobile": !this.props.colWidthsOnMobile })}>
                    {this.props.colWidths.map((cw, i) => <col key={i} width={cw} />)}
                </colgroup>}
                {this.props.head && this.props.head()}
                { (hasActual || hasHistory) && this.renderActualTable(this.props.actualData, hasActual, hasHistory)}
            </table>
        );
    }

    renderActualTable = (data, hasActual, hasHistorical) => {
        let dataSlice = [];
        let hasExtraRow = hasHistorical;
        let showPagingOrAll = false
        if (this.props.pageSize === null || this.props.pageSize === undefined) {
            dataSlice = data;
        } else {
            const min = this.props.isLimited ? 0 : (this.state.page.actual - 1) * this.props.pageSize;
            const max = this.getMaxItemIndex(data.length, this.props.pageSize, this.props.isLimited, false);
            hasExtraRow = (data.length > this.props.pageSize) || hasHistorical;
            dataSlice = data.slice(min, max);
            showPagingOrAll = data.length > this.props.pageSize;
        }

        return (
            <tbody className={classnames({ 'old-style-stripped': this.props.stripped === true })}>
                { hasActual && this.props.actualRow && dataSlice.map(r => this.props.actualRow(r))}
            </tbody>
        );
    }
    renderShowAll = (totalCount) => {
        return (
            <div>
                <a className="link-functional" target="_blank" href={this.props.showAllLink}>
                    <Trans i18nKey="Report.Common.ShowAll" />&nbsp;({totalCount})
                </a>
            </div>
        );
    }
    getMaxItemIndex = (count, pageSize, isLimited, isHistory) => {
        if (isLimited)
            return count < pageSize ? count : pageSize;
        return pageSize * this.state.page[isHistory ? 'historical' : 'actual'] > count
            ? count : pageSize * this.state.page[isHistory ? 'historical' : 'actual'];
    }
}

export default OldStyleTableWithHistory