function getElementBoundingRect(className, selector) {
    const br = ((document.getElementsByClassName(className) || [])[0] || {getBoundingClientRect:function(){return undefined}}).getBoundingClientRect();
    if(br === null || br === undefined) { return 0; }

    return selector(br) || 0;
}

function getClientWindowHeight() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; //cross-browser version
}

function getClientWindowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth; //cross-browser version
}


function getScrollY() {
    return window.scrollY || document.documentElement.scrollTop;
}

function getElementAttrib(selector, attribName) {
    return ((document.querySelectorAll(selector) || [])[0] || {getAttribute:function(){return undefined}}).getAttribute(attribName);
}

function getElementVal(selector) {
    return ((document.querySelectorAll(selector) || [])[0] || {}).value;
}

function getElementHeight({id, className}) {
    if(id !== null && id !== undefined) {
        return (document.getElementById(id) || {}).clientHeight || 0;
    }
    if(className !== null && className !== undefined) {
        return ((document.getElementsByClassName(className) || [])[0] || {}).clientHeight || 0;
    }
    return 0;
}

function showElement(id) {
    document.getElementById(id).style.display = '';
}

function hideElement(id) {
    document.getElementById(id).style.display = 'none';
}

function setElementVal(id, val) {
    document.getElementById(id).value = val;
}

function setElementStyleViaSelector(selector, property, val) {
    return ((document.querySelectorAll(selector) || [])[0] || {getAttribute:function(){return undefined}}).style[property] = val;
}

function setElementStyle(id, property, val) {
    document.getElementById(id).style[property] = val;
}

function removeElementAttr(id, attr) {
    document.getElementById(id).removeAttribute(attr);
}

function setElementAttr(id, attr, val) {
    document.getElementById(id).setAttribute(attr, val);
}

function emptyElement(id) {
    document.getElementById(id).innerHTML = '';
}

function setElementText(id, txt) {
    document.getElementById(id).innerText = txt;
}

function removeElementClass(id, className) {
    document.getElementById(id).classList.remove(className)
}

function addElementClass(id, className) {
    document.getElementById(id).classList.add(className)
}

function removeElementClassViaSelector(selector, className) {
    return ((document.querySelectorAll(selector) || [])[0] || {getAttribute:function(){return undefined}}).classList.remove(className);
}

function addElementClassViaSelector(selector, className) {
    return ((document.querySelectorAll(selector) || [])[0] || {getAttribute:function(){return undefined}}).classList.add(className);
}

export { addElementClassViaSelector, removeElementClassViaSelector, addElementClass, removeElementClass, setElementStyleViaSelector, getElementBoundingRect, getClientWindowHeight, getClientWindowWidth, getScrollY, getElementHeight, getElementAttrib, getElementVal, showElement, hideElement, setElementVal, setElementStyle, removeElementAttr, emptyElement, setElementText, setElementAttr }