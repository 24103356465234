import React from 'react';
import { Route, withRouter, Switch } from 'react-router';

import HistorySearchComponent from './history/historySearchComponent';
import HistoryResultComponent from './history/historyResultComponent';


class HistoryComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path={`${this.props.match.url}/result`} component={HistoryResultComponent} />
                    <Route path={`${this.props.match.url}`} component={HistorySearchComponent} />
                </Switch>
            </React.Fragment>
        );
    }
}
export default withRouter(HistoryComponent);
