import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'react-sm-select';

import { redirectTo, createQs, hasPermission } from '../../store/apiWrapper';
import { appContextActions } from '../../store/appContextStore';
import { customParseDate } from '../common/customParseDate';
import { hasRatingTypePermission } from '../common/ratingTypePermission'
import FieldHorizontal from '../common/formFields/fieldHorizontal'
import InputControl from '../common/formFields/inputControl';

const ratingTypes = ["smecz", "corpcz", "smesk", "nonProfitOrganizationcz", "entrepreneurcz", "housingCooperativecz", "municipalitycz", "municipalitysk"]

class HistorySearchComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ico: "", filterDateFrom: this.getDateFrom(), filterDateTo: new Date(), name: "", ratingType: ["all"], ratingFrom: [], ratingTo: [],
            ratingTypes: this.getRatingTypes(), customerId: []
        }
    }

    componentDidMount() {
        this.updateRatingsValues(this.state.ratingType);
        if (!this.props.ratingRange.isLoading && !hasPermission(null, "UserRatingMSP") && this.props.ratingRange.ratingRange.length > 0 && (this.props.ratingRange.rating[0] === this.state.ratingType[0] && (this.state.ratingTo.length === 0 || this.state.ratingFrom.length === 0))) {
            this.setState({
                ratingFrom: [this.props.ratingRange.ratingRange[0]],
                ratingTo: [this.props.ratingRange.ratingRange[this.props.ratingRange.ratingRange.length - 1]]
            });
        }
        if (!this.props.domainsHistory.isLoading && hasPermission(null, "UserCCB"))
            this.props.loadDomainHistory();
    }

    getDateFrom = () => {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        return customParseDate(formatDate(new Date(y, m - 1, 1)), "L", moment.locale());
    }

    getRatingTypes = () => {
        let types = ["all"]
        ratingTypes.forEach(element => {
            if (hasRatingTypePermission(element)) {
                types.push(element);
            }
        });
        return types;
    }

    componentDidUpdate(){
        if (!this.props.ratingRange.isLoading && !hasPermission(null, "UserRatingMSP") && this.props.ratingRange.ratingRange.length > 0 && (this.props.ratingRange.rating[0] === this.state.ratingType[0] && (this.state.ratingTo.length === 0 || this.state.ratingFrom.length === 0))) {
            this.setState({
                ratingFrom: [this.props.ratingRange.ratingRange[0]],
                ratingTo: [this.props.ratingRange.ratingRange[this.props.ratingRange.ratingRange.length - 1]]
            });
        }
        if (!this.props.domainsHistory.isLoading && hasPermission(null, "UserCCB"))
            this.props.loadDomainHistory();
    }

    updateRatingsValues = (code) => {
        if (!hasPermission(null, "UserRatingMSP")) {
            this.props.loadRatingRange(code);
        }
    }

    handleSearchSubmit = (event) => {
        event.preventDefault();
        if (hasPermission(null, "UserCCB")) {
            redirectTo(`/history/result?${createQs({ name: this.state.name, ico: this.state.ico, ratingType: this.state.ratingType[0], ratingFrom: this.state.ratingFrom[0], ratingTo: this.state.ratingTo[0], filterDateFrom: moment(this.state.filterDateFrom).toISOString(), filterDateTo: moment(this.state.filterDateTo).toISOString(), customerId: this.state.customerId[0] })}`);
        }
        else if (hasPermission(null, "UserRatingCalc") || hasPermission(null, "PartnerView")) {
            redirectTo(`/history/result?${createQs({ name: this.state.name, ico: this.state.ico, ratingType: this.state.ratingType[0], ratingFrom: this.state.ratingFrom[0], ratingTo: this.state.ratingTo[0], filterDateFrom: moment(this.state.filterDateFrom).toISOString(), filterDateTo: moment(this.state.filterDateTo).toISOString() })}`);
        }
        else if (hasPermission(null, "UserRatingMSP")) {
            redirectTo(`/history/result?${createQs({ name: this.state.name, ico: this.state.ico, ratingType: this.state.ratingType[0], filterDateFrom: moment(this.state.filterDateFrom).toISOString(), filterDateTo: moment(this.state.filterDateTo).toISOString() })}`);
        }
        else{
            redirectTo(`/history/result?${createQs({ ratingFrom: this.state.ratingFrom[0], ratingTo: this.state.ratingTo[0], filterDateFrom: moment(this.state.filterDateFrom).toISOString(), filterDateTo: moment(this.state.filterDateTo).toISOString() })}`);
        }

    }

    changeEvent = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return (
            <div className="columns work-container">
                <div className="column left-navbar-container">
                </div>
                <div className="column right-body-container is-three-quarters">
                    <div className="container is-centered columns">
                        {this.renderSearch()}
                    </div>
                </div>
                <div className="column">
                </div>
            </div>
        )
    }

    renderSearch() {
        return (
            <div className="column is-6 form-layout">
                <div className="box">
                    <div className="has-text-centered">
                        <h1 className="title is-4">{this.props.t('IRating.History.Search.Title')}</h1>
                    </div>
                    <form onSubmit={(e) => this.handleSearchSubmit(e)}>
                        <div className="container">
                            {(hasPermission(null, "UserRatingMSP") || hasPermission(null, "UserCCB") || hasPermission(null, "UserRatingCalc") || hasPermission(null, "PartnerView")) &&
                            <>
                            <FieldHorizontal label={this.props.t('IRating.History.RatingType')}>
                                <MultiSelect mode="single" isLoading={this.props.ratingRange.isLoading} value={this.state.ratingType}  options={this.state.ratingTypes.map(x => { return { value: x, label: this.props.t(`IRating.Report.${x.toLowerCase()}`) } })}
                                                        onChange={ratingType => {this.setState({ ratingType, ratingFrom: [], ratingTo: [] });
                                                        this.updateRatingsValues(ratingType);}} valuePlaceholder={this.props.t(`Common.Select`)}
                                                        searchPlaceholder={this.props.t(`Common.Search`)} allSelectedLabel={this.props.t(`Search.Common.All`)}/>
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.Ico')}>
                                <InputControl type="text" fieldName="ico" value={this.state.ico} placeholder={this.props.t('IRating.Report.Search.Ico')} onChange={this.changeEvent}/>
                            </FieldHorizontal>
                            <FieldHorizontal label={this.props.t('IRating.Report.Search.Name')}>
                            <InputControl type="text" fieldName="name" value={this.state.name} placeholder={this.props.t('IRating.Report.Search.Name')} onChange={this.changeEvent}/>
                            </FieldHorizontal>
                            </>
                            }
                            {!hasPermission(null, "UserRatingMSP") &&
                                <div className="field is-horizontal">
                                    <div className="field-label" style={{ whiteSpace: "nowrap" }}><label className="label">
                                        {this.props.t('IRating.History.Rating')}</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field-label">
                                            {this.props.t('IRating.History.From')}
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                        <MultiSelect mode="single" isLoading={this.props.ratingRange.isLoading} value={this.state.ratingFrom}  options={this.props.ratingRange.ratingRange.map(x => { return { value: x, label: x } })}
                                                        onChange={ratingFrom => this.setState({ ratingFrom })} valuePlaceholder={this.props.t(`Common.Select`)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field-label">
                                            {this.props.t('IRating.History.To')}
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                        <MultiSelect mode="single" isLoading={this.props.ratingRange.isLoading} value={this.state.ratingTo}  options={this.props.ratingRange.ratingRange.map(x => { return { value: x, label: x } })}
                                                        onChange={ratingTo => this.setState({ ratingTo })} valuePlaceholder={this.props.t(`Common.Select`)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="field is-horizontal">
                                <div className="field-label" style={{ whiteSpace: "nowrap" }}><label className="label">
                                    {this.props.t('IRating.History.Date')} </label>
                                </div>
                                <div className="field-body">
                                    <div className="field-label">
                                        {this.props.t('IRating.History.From')}
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                <DayPickerInput value={this.state.filterDateFrom} dayPickerProps={{
                                                    localeUtils: MomentLocaleUtils,
                                                    locale: moment.locale()
                                                }}
                                                    formatDate={formatDate} parseDate={customParseDate} inputProps={{ className: "input" }} onDayChange={day => this.setState({ filterDateFrom: day })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field-label">
                                        {this.props.t('IRating.History.To')}
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                <DayPickerInput value={this.state.filterDateTo} dayPickerProps={{
                                                    localeUtils: MomentLocaleUtils,
                                                    locale: moment.locale()
                                                }}
                                                    formatDate={formatDate} parseDate={customParseDate} inputProps={{ className: "input" }} onDayChange={day => this.setState({ filterDateTo: day })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { hasPermission(null, "UserCCB") &&
                                <FieldHorizontal label={this.props.t('IRating.History.Customer')}>
                                    <MultiSelect mode="single" enableSearch isLoading={this.props.domainsHistory.isLoading} value={this.state.customerId} options={this.props.domainsHistory.customers.map(x => { return { value: x.code, label: `${x.code} - ${x.name}` } })}
                                                            onChange={customerId => {this.setState({ customerId });}} valuePlaceholder={this.props.t(`Common.Select`)}
                                                            searchPlaceholder={this.props.t(`Common.Search`)} allSelectedLabel={this.props.t(`Search.Common.All`)}/>
                                </FieldHorizontal>
                            }
                            <div className="field is-horizontal">
                                <div className="field-label">
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input className="button is-primary" type="submit" value={this.props.t('IRating.Report.Search.OK')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default withTranslation("translation")(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(HistorySearchComponent));
