import Cookies from 'universal-cookie';
import { writeDebug, writeErr } from '../components/common/logger';
import { LOCATION_CHANGE } from 'react-router-redux';
import { errorType } from './appContextStore';
import { push } from 'react-router-redux'
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { getRandomValue } from './storeFunctions';

const cookies = new Cookies();
const langMap = { ".sk":"sk-SK", ".cz":"cs-CZ", ".en":"en-US", ".de":"de-DE" };
const momentLocaleMap = { "sk-SK": "sk", "cs-CZ": "cs", "en-US": "en-gb", "de-DE": "de" };
let requestMap = [];
let requestStack = [];
let requestTimer = undefined;

const getLanguage = () => {
    return cookies.get('Cribis_Culture') || langMap[(window.location.hostname.match(/(.cz$)|(.sk$)|(.en$)|(.de$)/gi) || ['.sk'])] || "sk-SK";
};

const getMomentLanguage = (lang) => {
    return momentLocaleMap[lang];
}

const setLanguage = (lang) => {
    cookies.set('Cribis_Culture', lang);
};

const isOnPath = function(config) {
    const {prefix, regex} = config;
    let result;
    const testTo = window.location.pathname + window.location.search;
    if(regex !== null && regex !== undefined){
        result = regex.test(testTo);
    }

    if(prefix !== null && prefix !== undefined) {
        const ix = testTo.indexOf(prefix);
        result = ix >= 0 && ix <= 1;
    }
    writeDebug('Path ['+testTo+'] evaluated ['+result+'] with config:', config)
    return result;
}

const getAuthorization = (bearer) => {
    if(localStorage === undefined || localStorage === null) {
        return "";
    }
    let auth = localStorage.getItem('auth');
    if(auth === null || auth === undefined) {
        return "";
    }
    auth = JSON.parse(auth);
    if(auth === null || auth === undefined || auth.token === null || auth.token === undefined) {
        return "";
    }

    return (bearer ? "Bearer " : "") + auth.token;
}

const createHeaders = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': getLanguage(),
        'Authorization': getAuthorization(true)
    };
};

// GET | POST | PUT must be raw functions to allow context propagation
const apiGet = function(url, params, onSuccess, onFail, always) { 
    queueApiCall.call(this, url, params, null, 'GET', onSuccess, onFail, always) 
};
const apiPost = function(url, params, data, onSuccess, onFail, always) {
    queueApiCall.call(this, url, params, data, 'POST', onSuccess, onFail, always) 
};
const apiPut = function(url, params, data, onSuccess, onFail, always) { 
    queueApiCall.call(this, url, params, data, 'PUT', onSuccess, onFail, always) 
};

const queueApiCall = function(url, params, data, method, onSuccess, onFail, always) {
    requestStack.push({url, params, data, method, onSuccess, onFail, always, context:this});
    if(requestTimer === undefined) { 
        requestTimer = setTimeout(() => processQueue(), 0);
    }
};

const processQueue = function() {
    if(requestStack.length === 0) {
        clearTimeout(requestTimer);
        requestTimer = undefined;
        writeDebug('Queue empty');
        return;
    }
    const item = requestStack.pop();
    writeDebug(`Queue item [${item.url}] processing begin`);
    
    apiFetch.call(item.context, item.url, item.params, item.data, item.method, item.onSuccess, item.onFail, () => {
        if(isFunction(item.always)) {
            item.always.call(item.context);
        }
        writeDebug(`Queue item [${item.url}] processing finished`);
        requestTimer = setTimeout(() => processQueue(), 0);
    });

    try {
        // if we have still valid token run in parallel
        if(Date.parse(JSON.parse(localStorage.getItem('auth')).expires) > (Date.now() + 30*1000)) {
            writeDebug('Queue parallel execution');
            requestTimer = setTimeout(() => processQueue(), 0);
        }
    } catch(ex) {
        writeErr('failed to queue work', ex);
    }
}

const apiFetch = function(url, params, data, method, onSuccess, onFail, always) {
    writeDebug("fetch - " + method + " - " + url);
    let config = { method: method, headers:createHeaders(), cache: "no-cache", url, paramsSerializer: createQs };
    if(data !== null && data !== undefined) {
        config.data = JSON.stringify(data);
    }

    const t = Date.now();
    requestMap[generateRequestMapKey(url, params)] = t;
    config.params = Object.assign({}, params, { t });

    const ctx = this;
    axios.request(config)
        .then(res => {
            const tt = requestMap[generateRequestMapKey(url, params)];
            const ttt = parseInt(res.headers['x-timestamp'], 10) || Number.MAX_SAFE_INTEGER;
            if(ttt >= tt && isFunction(isFunction)) {
                onSuccess.call(ctx, res.data);
            }
        })
        .catch(err => {
            if(isFunction(onFail)) {
                onFail.call(ctx, err.response);
            }
        })
        .then(() => {
            if(isFunction(always)) {
                always.call(ctx);
            }
        });
};

const generateRequestMapKey = function (url, params) {
    return url + (params ? createQs(params) : '');
}

const isFunction = function(obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
}

const parseQuery = function(url) {
    let params = {};
    const parts = (url || '').split(/[?]/);
    if(parts.length !== 2) {
        return params;
    }

    let arr = parts[1].split(/&|=/);
    for(let i = 0; i < arr.length; i+=2) {
        params[arr[i]] = decodeURIComponent(arr[i + 1]);
    }
    return params
};

const createQs = function(obj) {
    const keys = Object.keys(obj);
    return keys.map(key => {
		const value = obj[key];

		if (value === undefined || value === null) {
			return '';
		}

		return encodeURI(key) + '=' + encodeURI(value);
	}).filter(x => x.length > 0).join('&');
}

const setupInterceptors = (store, history) => {
    const interceptor = axios.interceptors.response.use(response =>  response, error => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if(error.response.status === 401) {
            if(auth !== null && auth !== undefined && Date.parse(auth.expires) <= Date.now()) {
                const loginPath = auth.isGeneralUser ? '/login-user' : '/login';
                axios.interceptors.response.eject(interceptor);
                return axios.post(`/api/account${loginPath}`, 
                    { grant_type:'refresh_token', refresh_token: auth.refreshToken }, 
                    { headers:createHeaders(), cache: "no-cache" })
                .then(respo => {
                    if(respo.data.data.success) {
                        store.dispatch({type:'USER_LOGIN', data:respo.data.data});
                        error.response.config.headers['Authorization'] = getAuthorization(true);
                        return axios.request(error.response.config);
                    } else {
                        store.dispatch({type:'LOGIN_EXPIRED'});
                        if(!isOnPath({prefix:loginPath})) {
                            store.dispatch(push(`${loginPath}?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`));
                        } else {
                            store.dispatch({type:LOCATION_CHANGE, payload:{ pathname:loginPath, hash:'', search:''}});
                        }
                        return Promise.reject(error);
                    }
                }).catch(error => {
                    if (isObjectPathDefined(error, 'response.data.error.message')) {
                        store.dispatch({ type: errorType, message: error.response.data.error.message });
                    }
                    if(!isOnPath({prefix:loginPath})) {
                        store.dispatch(push(`${loginPath}?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`));
                    } else {
                        store.dispatch({type:LOCATION_CHANGE, payload:{ pathname:loginPath, hash:'', search:''}});
                    }
                    return Promise.reject(error);
                }).finally(() => setupInterceptors(store, history));
            } else {
                store.dispatch({type:'LOGIN_EXPIRED'});
                store.dispatch(push(`/login?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`));
                return Promise.reject(error);
            }
        } else if (error.response.status === 403) {
            if (isObjectPathDefined(error, 'response.data.error.message')) {
                store.dispatch({ type: errorType, message: error.response.data.error.message });
            }
        } else if (error.response.status === 404) {

        }

        return Promise.reject(error);
    });
};

const setupAuthWatch = (store, history) => {
    let auth = localStorage.getItem('auth');
    if(auth === null || auth === undefined) {
        if(!isOnPath({prefix:'/login'})) {
            writeDebug('Authentication expired, dispatching [LOGIN_EXPIRED] event')
            store.dispatch({type:'LOGIN_EXPIRED'})
        }
        setTimeout(() => setupAuthWatch(store, history), 1000);
        return;
    }
    auth = JSON.parse(auth);
    const authDefined = auth !== null && auth !== undefined && auth.expires !== null && auth.expires !== undefined;
    if(authDefined) {
        const tokenExpires = Date.parse(auth.expires) <= Date.now();
        const refreshExpires = auth.refreshExpires === undefined || (auth.refreshExpires !== undefined && Date.parse(auth.refreshExpires) <= Date.now());
        if(tokenExpires && refreshExpires && !isOnPath({prefix:'/login'})) {
            writeDebug('Authentication expired, dispatching [LOGIN_EXPIRED] event')
            store.dispatch({type:'LOGIN_EXPIRED'});
        }
    } else {
        if(!isOnPath({prefix:'/login'})) {
            writeDebug('Authentication object missing, dispatching [LOGIN_EXPIRED] event')
            store.dispatch({type:'LOGIN_EXPIRED'});
        }
    }
    setTimeout(() => setupAuthWatch(store, history), 1000);
}

const isObjectPathDefined = function(object, path) {
    if(object === null || object === undefined) {
        return false;
    }
    let o = object
    const p = path.split(/[.]/);
    for(let i = 0; i < p.length; i++) {
        if(o === null || o === undefined || !o.hasOwnProperty(p[i])) {
            return false;
        }
        o = o[p[i]];
    }
    return o !== null && o !== undefined;
}

const getHostUrl = function() {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
}

const hasPermission = function(user, permission) {
    if(user === null || user === undefined){
        user = {authentication :JSON.parse(localStorage.getItem('auth')) };
    }

    if(isObjectPathDefined(user, "authentication.permissions") && user.authentication.permissions.length) {
        const phash = CryptoJS.SHA1(permission.toUpperCase()).toString().toUpperCase();
        if(user.authentication.permissions.indexOf(phash) !== -1) {
            return true;
        }
    }
    return false;
}

const setupRedirecCore = function(store, history) {
    window.__store = store;
    window.__history = history;
}

function redirectTo(url) {
    const redir = url !== null && url !== undefined ? decodeURIComponent(url) : '/';
    writeDebug("Redirecting to "+redir);
    window.__store.dispatch({type:LOCATION_CHANGE, payload:{ pathname:redir}});
    window.__history.push(redir);
}

const generateId = function () {
    var S4 = function () {
        return getRandomValue().toString(16).slice(-4);
    };
    return ('_' + S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
}

export { apiGet, apiPost, apiPut, getLanguage, getMomentLanguage, setLanguage, parseQuery, 
    createQs, setupInterceptors, isObjectPathDefined, getAuthorization, 
    getHostUrl, hasPermission, isOnPath, setupRedirecCore, redirectTo, generateId };