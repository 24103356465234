import React from 'react';
import classnames from 'classnames'

const ButtonControl = ({type, onClick, label, isPrimary, disabled }) => {
    return (
        <React.Fragment>
            <div className="control">
                <button className={classnames("button", {"is-primary" : isPrimary})} type={type} onClick={onClick} disabled={disabled}>{label}</button>
            </div>
        </React.Fragment>
    );
}

export default ButtonControl;