import React from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router';

import HistoryResultComponent from './history/historyResultComponent';
import ReportResultComponent from './report/reportResultComponent';
import { hasPermission } from '../store/apiWrapper';


class ApprovalsComponent extends React.Component {
    render() {
        if(hasPermission(null, "UserCCB")){
        return (
            <React.Fragment>
                <Switch>
                    <Route path={`${this.props.match.url}/result`} component={ReportResultComponent} />
                    <Route path={`${this.props.match.url}`} component={HistoryResultComponent} />
                </Switch>
            </React.Fragment>
        );
        }
        else {
            return (<Redirect to={`/notAuthorized`} />);
        }
    }
}
export default withRouter(ApprovalsComponent);
