import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appContextActions } from '../../store/appContextStore';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';

class LoginComponentUser extends React.Component {
    state = { username: '', pswd: '', newPswd: '', newPswd2: '', error: false, message: undefined };

    componentDidMount = () => {
        if (this.props.authentication.isAuthorized) {
            if (!this.props.navbar) { this.props.redirectBack(window.location.href); }
        }
    }

    handleSubmit = (event, type) => {
        event.preventDefault();
        if (type === 'login') {
            this.props.loginGeneralUser(this.state.username, this.state.pswd, window.location.href);
            return;
        }
    }

    changeEvent = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    showError = (error) => {
        if (error === null || error === undefined) {
            return "";
        }
        return (
            <div className="columns">
                <div className="column is-4 is-offset-4">
                    <div className="notification is-danger">{error}</div>
                </div>
            </div>
        );
    };

    renderAsForm = () => {
        if (this.props.authentication.isAuthorized) {
            return "";
        }
        return this.renderLoginForm();
    }

    renderLoginForm = () => {
        return (
            <section className="section">
                <form onSubmit={(e) => this.handleSubmit(e, 'login')}>
                    <div className="columns">
                        <div className="column is-4 is-offset-4">
                            <h1 className="subtitle is-4">{this.props.t('Login.FormCaption')}</h1>
                        </div>
                    </div>
                    {this.props.authentication.isAuthorized === false && this.showError(this.props.authentication.errorMessage)}
                    <div className="columns">
                        <div className="column is-4 is-offset-4">
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input className="input" type="text" placeholder={this.props.t('Login.Username')} disabled={this.props.loading} name='username' value={this.state.username} onChange={this.changeEvent} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="user" /></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className="input" type="password" placeholder={this.props.t('Login.Password')} disabled={this.props.loading} name="pswd" value={this.state.pwsd} onChange={this.changeEvent} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="lock" /></span>
                                </p>
                            </div>
                            <div className="field columns">
                                <div className="column">
                                </div>
                                <div className="column">
                                    <p className="control">
                                        {!this.props.authentication.loading && (<input type="submit" value={this.props.t('Login.Login')} className="is-success button is-pulled-right" disabled={this.props.loading} />)}
                                        {this.props.authentication.loading && (<span className="is-info button is-pulled-right"><FontAwesomeIcon icon="cog" spin /></span>)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        )
    }

    render() {
            return this.renderAsForm();
    }
}
export default withTranslation("translation")(withRouter(connect(
    state => state.appContext,
    dispatch => bindActionCreators(appContextActions, dispatch)
)(LoginComponentUser)));
