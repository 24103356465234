export const REPORT_TYPES = {
    municipality: "municipality",
    sme: "sme",
    corp: "corp",
    entrepreneur: "entrepreneur",
    nonProfitOrganization: "nonProfitOrganization",
    housingCooperative: "housingCooperative"
};

export const COUNTRY_TYPES = {
    CZ: "cz",
    SK: "sk"
};